import React from 'react'
import prop from 'lodash/fp/prop'
import { v4 as uuid } from 'uuid'
// import { Statsig, StatsigContext } from 'statsig-react'

import { CompareCredit } from '../../../types'
import { useFetchCardsSlug } from '../../utils'
import { buildSecureHref } from '../../utils/buildSecureHref'
import { getOrderId } from '../../utils/get-order-id'

export const ApplyNowLink = (
  externalId?: string,
  orderId?: string,
  orderIdsBySlug?: { [key: string]: string },
) => {
  const ApplyNowLinkRender = ({
    children,
    value,
  }: {
    children: any
    value: { slug: string }
  }) => {
    const cardsSlug = value.slug ? [value.slug] : []
    const {
      cards: [productTip],
    } = useFetchCardsSlug(cardsSlug)

    const externalTagId = externalId ? externalId : '00'

    const slug = productTip ? prop('slug', productTip) : ''

    let applyNowLinkParameters: CompareCredit.Card['applyNowLinkParameters'] =
      []
    if (
      productTip &&
      productTip._type !== 'product' &&
      productTip._type !== 'nonPaidCard'
    ) {
      applyNowLinkParameters = productTip?.applyNowLinkParameters
    }

    // Secure page exposure experiment
    // const [secureExp, setSecureExp] = React.useState<any>(null)
    // React.useEffect(() => {
    //   if (!initialized) return
    //   const exp = Statsig.getExperiment('secure_direct')
    //   const group = exp.get('group', 'control')
    //   setSecureExp(group)
    // }, [initialized])

    const href = buildSecureHref({
      orderId: getOrderId({ orderId, slug, orderIdsBySlug }) || uuid(),
      slug,
      externalId: externalTagId,
      pathName: typeof window !== 'undefined' ? window?.location?.pathname : '',
      linkParams: applyNowLinkParameters,
      expGroup: null,
    })

    return (
      <>
        {slug ? (
          <a
            className="mt-8 mb-2 / text-primary-bright / hover:text-primary-mid"
            href={href}
            target="_blank"
            rel="noreferrer"
          >
            {children}
          </a>
        ) : (
          <span>{children}</span>
        )}
      </>
    )
  }
  return ApplyNowLinkRender
}
