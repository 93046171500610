import React from 'react'
import Link from 'next/link'
import { StatsigContext, useStatsigClient } from '@statsig/react-bindings'

import { CompareCredit } from '../../../types/compare-credit'
import { buildSecureHref } from '../../utils/buildSecureHref'
import { UserContext } from '../UserContext'
import { ModalLeaveBehindContext } from '../modal-leave-behind-context'
import { getRprQuery, isStorageAccessible } from '../../utils/'
import { fireHoldUpModal } from '../../clients/segment'
import { v4 as uuid } from 'uuid'

export function LinkToRedirect({
  externalId,
  children,
  linkParams = [],
  slug,
  query,
  orderId,
  position,
  profileId,
}: {
  children: React.ReactElement
  linkParams?: CompareCredit.Card['applyNowLinkParameters']
  slug: string
  query?: string
  orderId: string
  position?: number
  externalId: string
  profileId?: string
}) {
  const [href, setHref] = React.useState('')
  const { search, entryQuery } = React.useContext(UserContext)
  const queryToUse = query || getRprQuery(search)
  const { getExperiment } = useStatsigClient()

  const { client } = React.useContext(StatsigContext)

  React.useEffect(() => {
    if (client.loadingStatus !== 'Ready') return
    const exp = getExperiment('secure_direct')
    const group = exp.get('group', 'control') as 'control' | 'direct' | null
    const pathName = window.location.pathname

    const utm_source = entryQuery?.utm_source
    const formattedOrderId = orderId ? orderId : uuid()
    const utmSourceFormattedOrderId = utm_source
      ? `${utm_source}_${formattedOrderId}`
      : formattedOrderId

    const builtHref = buildSecureHref({
      orderId: utmSourceFormattedOrderId,
      profileId,
      linkParams,
      slug,
      query: queryToUse,
      externalId,
      pathName,
      expGroup: group,
      position,
    })
    setHref(builtHref)
  }, [
    slug,
    externalId,
    query,
    profileId,
    orderId,
    position,
    JSON.stringify(linkParams),
    client.loadingStatus,
  ])

  // I. MODAL FUNCTIONS
  const {
    handleSetOpenModalLeaveBehind,
    shouldOpenModalLeaveBehindCapOneShopping,
    isModalLeaveBehindCard,
    showOpenLeaveBehindModalExtension,
    modalLeaveBehindId,
    modalLeaveBehindTipSlug,
  } = React.useContext(ModalLeaveBehindContext)

  const sessionStorageExist = isStorageAccessible()

  // II. Session storage so leave behind extension fires only once
  // const leaveBehindExtension = sessionStorageExist ? sessionStorage.getItem('modalLeaveBehindExtension') : null

  const handleFireLeaveBehindModal = React.useCallback(() => {
    fireHoldUpModal({
      modal_id: modalLeaveBehindId || '',
      type: 'rendered',
      meta: {
        redirect_order_id: orderId || '',
      },
    })
  }, [modalLeaveBehindId, orderId])

  const handleModalLeaveBehind = React.useCallback(() => {
    // 1. Leave behind
    const win = window.open(href, '_blank')
    const leaveBehindSSCapOne = sessionStorageExist
      ? sessionStorage.getItem(`modalLeaveBehindCapOne`)
      : null

    const leaveBehindSSTipSlug = sessionStorageExist
      ? sessionStorage.getItem(`modalLeaveBehind-${modalLeaveBehindTipSlug}`)
      : null

    const leaveBehindSSExtension = sessionStorageExist
      ? sessionStorage.getItem(`modalLeaveBehindExtension`)
      : null

    // 2. Fire leave behind
    fireHoldUpModal({
      modal_id: modalLeaveBehindId || '',
      type: 'apply_now_clicked',
      meta: {
        redirect_order_id: orderId || '',
      },
    })

    // 3. Trigger events
    const intervalId = setInterval(() => {
      if (win?.opener == null) {
        if (
          leaveBehindSSCapOne !== 'true' &&
          shouldOpenModalLeaveBehindCapOneShopping &&
          !leaveBehindSSExtension
        ) {
          handleSetOpenModalLeaveBehind()
          // Statsig.logEvent('modal_leave_behind_cc_one_shop_shown')
          handleFireLeaveBehindModal()
        } else if (
          leaveBehindSSTipSlug !== 'true' &&
          !shouldOpenModalLeaveBehindCapOneShopping &&
          !leaveBehindSSExtension
        ) {
          handleSetOpenModalLeaveBehind()
          // Statsig.logEvent('modal_leave_behind_shown')
          handleFireLeaveBehindModal()
        }
        if (
          leaveBehindSSExtension !== 'true' &&
          showOpenLeaveBehindModalExtension
        ) {
          handleSetOpenModalLeaveBehind()
          // Statsig.logEvent('modal_leave_behind_extension_shown')
          handleFireLeaveBehindModal()
        }
        // if (
        //   ![
        //     'travel-rewards',
        //     'discover-it-balance-transfer',
        //     'active-cash',
        //   ].includes(slug) &&
        //   leaveBehindExtension !== 'true'
        // ) {
        //   setOpenModalLeaveBehindExtension(true)
        //   // logEvent('modal_leave_behind_extension_shown')
        //   if (sessionStorageExist) {
        //     sessionStorage.setItem('modalLeaveBehindExtension', 'true')
        //   }
        // }

        clearInterval(intervalId)
      }
    }, 500)
  }, [
    href,
    slug,
    shouldOpenModalLeaveBehindCapOneShopping,
    handleSetOpenModalLeaveBehind,
    handleFireLeaveBehindModal,
  ])

  return isModalLeaveBehindCard || showOpenLeaveBehindModalExtension ? (
    <>
      <button
        data-cy="apply-now-element"
        data-apply-now-href={href}
        className="c-ltr-modal-trigger / w-full"
        onClick={handleModalLeaveBehind}
      >
        {children}
      </button>
    </>
  ) : (
    <Link
      data-cy="apply-now-element"
      data-apply-now-href={href}
      href={href}
      target="_blank"
    >
      {children}
    </Link>
  )
}
