import React from 'react'
import Link from 'next/link'
import Image from 'next/image'
import { PortableText, PortableTextBlockComponent } from '@portabletext/react'

import { CompareCredit } from '../../../types/compare-credit'
import { CardArt, LabelAd, LinkToRedirect } from 'components/'
import { SecureSite } from 'components/secure-site'
import { clickPromotion, clickRatesAndFees } from 'clients/segment'
import { supify } from 'utils/'
import cn from 'utils/classnames'

export function RecommendedCard(props: {
  recCard: CompareCredit.FormattedCard
  ribbonText: string | null
  comparisonCart: CompareCredit.FormattedCard[]
  isPrime: boolean
  recCardIsInCart: boolean
  orderId: string
  categoryId: string
  externalId: string
}) {
  const {
    recCard,
    ribbonText,
    comparisonCart,
    isPrime,
    recCardIsInCart,
    orderId,
    categoryId,
    externalId,
  } = props

  const ExpertReviewHighlight = ({ children }: { children: any }) => (
    <span>{children}</span>
  )

  function AttributeRenderer<K extends keyof CompareCredit.Entity>(attrProps: {
    value: { attribute: K }
  }) {
    return (
      <span>{props.recCard[attrProps.value.attribute] as React.ReactNode}</span>
    )
  }

  const handleClick = () => {
    clickPromotion({
      name: 'reco.comp.cart',
      promotion_id: recCard.slug,
    })
  }

  if (!recCardIsInCart && isPrime) {
    return (
      <div
        id={`cart-${recCard.slug}`}
        className={cn(
          'relative / sm:w-1/3 md:flex-grow lg:grow-0 / px-2 xxs:px-4 py-5 sm:pt-2 sm:pb-5 xl:px-5 / border-b border-gray-300 sm:border-b-0 sm:border-r / bg-primary-light-bg',
          {
            'lg:w-1/4': comparisonCart.length >= 3,
          },
        )}
      >
        <div className="flex flex-row flex-wrap / sm:flex-col sm:items-center sm:justify-start / lg:flex-row lg:items-start lg:justify-start">
          <div className="w-full shrink-0 mb-0 / sm:text-center lg:text-left">
            <p className="c-ribbon c-ribbon--slimmer c-ribbon--rt / relative inline-block / w-auto mb-2 px-2 py-1 / font-bold text-primary text-xs / bg-tetriary / sm:hidden lg:inline-block">
              {ribbonText || 'Recommended'}
            </p>
            <p className="c-ribbon c-ribbon--slimmer / relative / w-auto mx-auto mb-2 px-2 py-1 / font-bold text-primary text-xs / bg-tetriary / hidden sm:inline-block lg:hidden">
              {ribbonText || 'Recommended'}
            </p>
          </div>

          <div className="shrink-0 / w-1/4 sm:w-24 lg:w-1/4 / mb-1 / text-center">
            <div className="c-card-art / w-full lg:mb-1 / text-center flex items-center justify-center text-xs">
              <div className="w-full relative / rounded-sm shadow / overflow-hidden">
                <LinkToRedirect
                  externalId={externalId}
                  linkParams={recCard.applyNowLinkParameters}
                  orderId={orderId}
                  slug={recCard.slug}
                >
                  <button
                    className="relative flex rounded-sm overflow-hidden"
                    aria-label={`Apply for the ${recCard.name} card`}
                    onClick={handleClick}
                  >
                    <span className="c-card-art__content / absolute / flex flex-wrap content-center items-center / z-10 left-0 / w-full h-full text-white">
                      <span className="w-full font-bold">Apply Now</span>
                    </span>
                    <CardArt
                      _rev={recCard._rev}
                      cardArt={recCard.cardArt}
                      imgClasses="w-full"
                      issuer={recCard.issuer.slug.current}
                      name={recCard.name}
                      orderId={orderId}
                      slug={recCard.slug}
                      categoryId={categoryId}
                      externalId={externalId}
                    />
                  </button>
                </LinkToRedirect>
              </div>
            </div>
            <Link
              href={`/credit-cards/${recCard.issuer.slug.current}/${recCard.slug}`}
              className="inline-block / w-full / text-fs11 text-center text-primary-bright leading-snug / hover:underline hover:text-primary-mid"
              onClick={handleClick}
            >
              More Details
            </Link>
            {recCard.ratesAndFees && recCard.ratesAndFees !== 'N/A' && (
              <a
                className="inline-block / w-full / text-fs11 text-center text-primary-bright leading-snug / hover:underline hover:text-primary-mid"
                href={recCard.ratesAndFees}
                onClick={() => {
                  clickRatesAndFees({
                    applyNowLink: recCard.applyNowLink,
                    component: 'Cart Cart Comparison Recommended',
                    name: recCard.name,
                    url: window.location.href,
                  })
                }}
                rel="noopener noreferrer"
                target="_blank"
              >
                Rates &amp; Fees
              </a>
            )}
            {recCard.issuer.slug.current === 'american-express' && (
              <p className="text-fs10 text-gray-600 / text-center / mb-3 md:mb-1 / w-full leading-4">
                Terms Apply
              </p>
            )}
          </div>
          <div className="w-3/4 sm:w-full lg:w-3/4 lg:pl-2 / pl-4 xs:pl-8 pr-8 sm:px-4 xl:pr-5 / sm:text-center lg:text-left">
            <Link
              href={`/credit-cards/${recCard.issuer.slug.current}/${recCard.slug}`}
              className="inline-block / w-full mb-1 sm:mb-2 / text-primary-mid leading-snug font-semibold sm:text-sm lg:text-sm / hover:text-primary-bright cursor-pointer / transition-all / focus:outline-none"
              dangerouslySetInnerHTML={{
                __html: supify(recCard.name),
              }}
              onClick={handleClick}
            />
            {/* this depends on which attribute we decide highlight... add one prop of card... */}
            <div className="flex flex-col / mb-2 sm:mb-3 / text-xs">
              <span className="font-bold">Key Benefit</span>
              <PortableText
                value={recCard.expertReviewHighlight as any}
                components={{
                  block: ExpertReviewHighlight as PortableTextBlockComponent,
                  types: {
                    cardAttribute: AttributeRenderer,
                  },
                }}
              />
            </div>

            <span className="inline-block / w-48 lg:w-30 sm:max-w-full lg:max-w-full lg:pr-1">
              <LinkToRedirect
                externalId={externalId}
                linkParams={recCard.applyNowLinkParameters}
                orderId={orderId}
                slug={recCard.slug}
              >
                <button
                  className="c-btn c-btn--primary / mb-1 py-1"
                  onClick={handleClick}
                >
                  Apply Now
                  <span className="c-btn__icon / ml-1 xs:ml-2">
                    <span className="relative w-3 h-3 sm:w-4 sm:h-4 / hidden xxs:inline-block align-middle">
                      <Image
                        alt="lock"
                        src="/static/icons/icon-lock-round-w.svg"
                        aria-hidden="true"
                        role="presentation"
                        fill
                        sizes="100vw"
                      />
                    </span>
                  </span>
                </button>
              </LinkToRedirect>
              <p className="text-fs13 text-gray-600 text-center">
                <SecureSite
                  issuerName={recCard.issuer.name}
                  issuerSecureSite={recCard.issuerSecureSite}
                />
              </p>
            </span>
          </div>
          <div className="absolute bottom-[1.25rem] sm:bottom-[1.25rem] right-[1rem] sm:right-[.5rem] lg:right-[1rem]">
            <LabelAd />
          </div>
        </div>
      </div>
    )
  } else {
    return null
  }
}
