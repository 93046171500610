import React from 'react'
import Image from 'next/image'
import { isMobileOnly } from 'react-device-detect'

import { LinkToRedirect } from '../../link-to-redirect'
import { CallToApply } from '../..'
import { firstIsVowel } from '../../../utils'
import { CompareCredit } from '../../../../types/compare-credit'
import { SecureSite } from '../../secure-site'
import { clickRatesAndFees } from '../../../clients/segment'

interface ProductCTAsProps {
  categoryId: string | undefined
  externalId: string | undefined
  clientCountry: string | undefined
  productTip: CompareCredit.FormattedCard
  query: string
  containerClasses?: string
  orderId: string
  layoutSlim?: boolean
}

export default function ProductCTAs(props: ProductCTAsProps) {
  const {
    externalId = '00',
    clientCountry,
    containerClasses = '',
    productTip,
    query,
    orderId,
    layoutSlim,
  } = props

  const issuerName = productTip.issuer.name
  const issuerSecureSite = productTip.issuerSecureSite

  return (
    <div
      className={`c-card-tile__cta / mx-auto leading-normal ${containerClasses}`}
    >
      <span className={`${productTip._type === 'card' ? 'mb-2' : 'mb-6'}`}>
        <LinkToRedirect
          externalId={externalId}
          linkParams={
            productTip._type === 'card' ? productTip.applyNowLinkParameters : []
          }
          orderId={orderId}
          query={query !== '' ? query : undefined}
          slug={productTip.slug}
        >
          <div
            className={`c-btn c-btn--primary text-lg / mb-1.5 / trk_card-tile_cta ${
              layoutSlim
                ? 'py-1.5 sm:py-2 / leading-snug max-w-none'
                : 'py-1.5 sm:py-2 max-w-xs'
            }`}
          >
            {productTip._type === 'card' ? 'Apply Now' : 'Learn More'}
            <span className="c-btn__icon / relative / h-4 w-4 / ml-2">
              <Image
                alt="Lock Icon"
                src={
                  productTip._type === 'card'
                    ? '/static/icons/icon-lock-round-w.svg'
                    : '/static/icons/icon-chevron-r-white.svg'
                }
                fill
                sizes="100vw"
              />
            </span>
          </div>
        </LinkToRedirect>
      </span>

      <p className="c-card-tile__secure-site text-fs13 text-gray-600 / text-center leading-tight / mb-3">
        <SecureSite
          issuerName={issuerName}
          issuerSecureSite={issuerSecureSite}
        />
      </p>

      {productTip._type === 'card' &&
        productTip.phoneNumber &&
        isMobileOnly &&
        clientCountry === 'US' && (
          <div className="w-full mt-3">
            <p className="mb-3">
              <p className="mb-3">
                <CallToApply classes="max-w-xs" entity={productTip} />
              </p>
            </p>

            <p className="text-xs text-gray-600 / text-center / -mt-1 mb-4 / md:self-start w-full max-w-xs leading-4">
              with {firstIsVowel(issuerName) ? 'an' : 'a'} {issuerName}{' '}
              representative
            </p>
          </div>
        )}

      {productTip._type === 'card' &&
        productTip.ratesAndFees &&
        productTip.ratesAndFees !== 'N/A' && (
          <p className="c-card-tile__rates-fees / text-primary-bright text-xs w-full text-center pt-1 mb-3">
            <a
              href={productTip.ratesAndFees}
              onClick={() => {
                clickRatesAndFees({
                  applyNowLink: productTip.applyNowLink,
                  component: 'Tip Single Card Tile',
                  name: productTip.name,
                  url: window.location.href,
                })
              }}
              rel="noopener noreferrer"
              target="_blank"
            >
              Rates &amp; Fees
            </a>
          </p>
        )}

      {productTip.issuer.slug.current === 'american-express' && (
        <p className="c-card-tile__terms-apply / text-fs10 text-gray-600 / text-center / mb-3 md:mb-1 / w-full leading-4">
          Terms Apply
        </p>
      )}
    </div>
  )
}
