import React from 'react'
import last from 'lodash/fp/last'
import Image from 'next/image'
import Link from 'next/link'
import {
  PortableText,
  PortableTextBlockComponent,
  PortableTextMarkComponent,
} from '@portabletext/react'

import pageRoutes from '../../pageRoutes.json'
import disclosure from '../../ssg/disclosures/disclosures.json'
import { getCurrentYear, nextImageHelper, supify } from '../../utils'
import { useIsUserOnBrave } from 'utils/useIsUserOnBrave'

type disclosure = { content: any[]; name: string }

const Normal: PortableTextBlockComponent = ({
  children,
}: React.PropsWithChildren) => <p className="text-gray-400 mb-6">{children}</p>

const AwardsLink: PortableTextMarkComponent = ({
  children,
  value,
}: React.PropsWithChildren<{
  value?: { href: string; url: string }
}>) => {
  return value ? (
    <a
      className="text-gray-300 font-bold / hover:text-gray-500"
      href={value.href || value.url || ''}
      rel="noopener noreferrer"
      target="_blank"
    >
      {children}
    </a>
  ) : (
    <p className="text-gray-400 mb-6">{children}</p>
  )
}

const FooterLink = (props: { text: string; href: string }) => {
  return (
    <Link href={props.href}>
      <span
        className="hover:text-primary-bright cursor-pointer / transition-all"
        dangerouslySetInnerHTML={{
          __html: supify(props.text),
        }}
      />
    </Link>
  )
}

export function Footer(props: {
  generalDisclaimer: disclosure
  editorialNote: disclosure
  compareCreditAwardsProgram: disclosure
}) {
  const userIsOnBrave = useIsUserOnBrave()

  return (
    <footer className="bg-primary text-white">
      <div className="flex" role="presentation">
        <span className="w-1/6 h-1 bg-primary-mid"></span>
        <span className="w-1/6 h-1 bg-primary-mid-light"></span>
        <span className="w-1/6 h-1 bg-secondary"></span>
        <span className="w-1/6 h-1 bg-secondary-light"></span>
        <span className="w-1/6 h-1 bg-primary-bright"></span>
        <span className="w-1/6 h-1 bg-primary-light"></span>
      </div>

      <div className="container-fluid pt-10 pb-24">
        <div className="flex flex-wrap text-fs13">
          <div className="flex flex-wrap w-full lg:justify-between / lg:mb-8">
            <div className="c-footer__category c-footer__category--first / w-1/2 sm:w-1/3 md:w-1/4 / mb-8 lg:mb-0 / pr-1 sm:pr-2">
              <p className="font-bold mb-2">Popular Credit Cards</p>
              <ul className="text-gray-400 lg:mb-10 leading-snug">
                <li className="mb-1.5">
                  <FooterLink
                    text="Top Credit Cards"
                    href="/credit-cards/best"
                  />
                </li>
                {/* <li className="mb-1.5">
                  <FooterLink
                    text="Citi Simplicity® Credit Card"
                    href="/credit-cards/citibank/simplicity"
                  />
                </li>
                <li className="mb-1.5">
                  <FooterLink
                    text="Citi® Diamond Preferred® Credit Card"
                    href="/credit-cards/citibank/diamond-preferred"
                  />
                </li> */}
                <li className="mb-1.5">
                  <FooterLink
                    text="Discover it® Cash Back Credit Card"
                    href="/credit-cards/discover/discover-it-cash-back"
                  />
                </li>
                {!userIsOnBrave && (
                  <li className="mb-1.5">
                    <FooterLink
                      text="Bank of America® Unlimited Cash Rewards Credit Card"
                      href="/credit-cards/bank-of-america/unlimited-cash-rewards"
                    />
                  </li>
                )}
                <li className="mb-1.5">
                  <FooterLink
                    text="AT&T Points Plus® Card from Citi"
                    href="/credit-cards/citibank/att-points-plus"
                  />
                </li>
                {/* <li className="mb-1.5">
                  <FooterLink
                    text="Chase Freedom Unlimited®"
                    href="/credit-cards/chase/freedom-unlimited"
                  />
                </li> */}
                {!userIsOnBrave && (
                  <li className="mb-1.5">
                    <FooterLink
                      text="Bank of America® Travel Rewards"
                      href="/credit-cards/bank-of-america/travel-rewards"
                    />
                  </li>
                )}
              </ul>
            </div>

            <div className="c-footer__category / w-1/2 sm:w-1/3 md:w-1/4 / mb-8 lg:mb-0 / pl-1 sm:pl-0 sm:pr-2">
              <p className="font-bold mb-2">Credit Card Rewards</p>
              <ul className="text-gray-400 leading-snug">
                <li className="mb-1.5">
                  <FooterLink
                    text="Travel Credit Cards"
                    href="/credit-cards/best/travel"
                  />
                </li>
                <li className="mb-1.5">
                  <FooterLink
                    text="Cash Back Credit Cards"
                    href="/credit-cards/best/cash-back"
                  />
                </li>
                <li className="mb-1.5">
                  <FooterLink
                    text="Rewards Credit Cards"
                    href="/credit-cards/best/rewards"
                  />
                </li>
                <li className="mb-1.5">
                  <FooterLink
                    text="Gas Credit Cards"
                    href="/credit-cards/best/gas"
                  />
                </li>
                <li className="mb-1.5">
                  <FooterLink
                    text="Dining Credit Cards"
                    href="/credit-cards/best/dining"
                  />
                </li>
                <li className="mb-1.5">
                  <FooterLink
                    text="Airline Credit Cards"
                    href="/credit-cards/best/airline"
                  />
                </li>
              </ul>
            </div>

            <div className="c-footer__category / w-1/2 sm:w-1/3 md:w-1/4 / mb-8 lg:mb-0 / pr-1 sm:pr-2">
              <p className="font-bold mb-2">Credit Card Categories</p>
              <ul className="text-gray-400 leading-snug">
                <li className="mb-1.5">
                  <FooterLink
                    text="Low Interest Credit Cards"
                    href="/credit-cards/best/low-interest"
                  />
                </li>
                <li className="mb-1.5">
                  <FooterLink
                    text="Balance Transfer Credit Cards"
                    href="/credit-cards/best/balance-transfer"
                  />
                </li>
                <li className="mb-1.5">
                  <FooterLink
                    text="No Annual Fee Credit Cards"
                    href="/credit-cards/best/no-annual-fee"
                  />
                </li>
                <li className="mb-1.5">
                  <FooterLink
                    text="No Foreign Fee Credit Cards"
                    href="/credit-cards/best/no-foreign-transaction-fee"
                  />
                </li>
                <li className="mb-1.5">
                  <FooterLink
                    text="Student Credit Cards"
                    href="/credit-cards/best/student"
                  />
                </li>
                <li className="mb-1.5">
                  <FooterLink
                    text="Business Credit Cards"
                    href="/credit-cards/business/best"
                  />
                </li>
                <li className="mb-1.5">
                  <FooterLink
                    text="No Annual Fee Business Credit Cards"
                    href="/credit-cards/business/no-annual-fee"
                  />
                </li>
              </ul>
            </div>

            <div className="c-footer__category / w-1/2 sm:w-1/3 md:w-1/4 / mb-8 lg:mb-0 / pl-1 sm:pl-0 sm:pr-2">
              <p className="font-bold mb-2">Credit Card Issuers</p>
              <ul className="text-gray-400 leading-snug">
                <li className="mb-1.5">
                  <FooterLink
                    text="Citibank Credit Cards"
                    href="/credit-cards/citibank"
                  />
                </li>
                <li className="mb-1.5">
                  <FooterLink
                    text="Capital One Credit Cards"
                    href="/credit-cards/capital-one"
                  />
                </li>
                <li className="mb-1.5">
                  <FooterLink
                    text="Chase Credit Cards"
                    href="/credit-cards/chase"
                  />
                </li>
                {!userIsOnBrave && (
                  <li className="mb-1.5">
                    <FooterLink
                      text="Bank of America Credit Cards"
                      href="/credit-cards/bank-of-america"
                    />
                  </li>
                )}
                <li className="mb-1.5">
                  <FooterLink
                    text="Wells Fargo Credit Cards"
                    href="/credit-cards/wells-fargo"
                  />
                </li>
                <li className="mb-1.5">
                  <FooterLink
                    text="American Express Credit Cards"
                    href="/credit-cards/american-express"
                  />
                </li>
                {/* <li className="mb-1.5">
                  <FooterLink
                    text="US Bank Credit Cards"
                    href="/credit-cards/us-bank/"
                  />
                </li> */}
                <li className="mb-1.5">
                  <FooterLink
                    text="Discover Credit Cards"
                    href="/credit-cards/discover"
                  />
                </li>
              </ul>
            </div>

            <div className="c-footer__category / w-1/2 sm:w-1/3 md:w-1/2 / mb-12 md:mb-8 lg:mb-0 / pr-1 sm:pr-2">
              <p className="font-bold mb-2">Cards by Credit Score</p>
              <ul className="text-gray-400 text-snug">
                <li className="mb-1.5">
                  <FooterLink
                    text="Excellent Credit Cards"
                    href="/credit-cards/credit-range/excellent"
                  />
                </li>
                <li className="mb-1.5">
                  <FooterLink
                    text="Good Credit Cards"
                    href="/credit-cards/credit-range/good"
                  />
                </li>
                <li className="mb-1.5">
                  <FooterLink
                    text="Fair Credit Cards"
                    href="/credit-cards/credit-range/fair"
                  />
                </li>
                <li className="mb-1.5">
                  <FooterLink
                    text="Poor Credit Cards"
                    href="/credit-cards/credit-range/poor"
                  />
                </li>
                <li className="mb-1.5">
                  <FooterLink
                    text="Limited Credit Cards"
                    href="/credit-cards/credit-range/limited"
                  />
                </li>
              </ul>
              <div className="hidden lg:block mt-8">
                <p className="font-bold mb-2">Connect</p>
                <ul className="flex flex-row flex-wrap lg:flex-nowrap">
                  <li className="mr-2 mb-2 xs:mb-0 lg:mr-1">
                    <a
                      className="c-social-link facebook / flex / items-center justify-center / w-10 h-10 rounded-full"
                      href="https://www.facebook.com/CompareCredit365/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="w-6 h-6">
                        <Image
                          alt="facebook white logo"
                          src="/static/icons/facebook-white.svg"
                          height={24}
                          width={24}
                          style={nextImageHelper.responsive}
                          sizes="100vw"
                        />
                      </div>
                    </a>
                  </li>
                  <li className="mr-2 mb-2 xs:mb-0 lg:mr-1">
                    <a
                      className="c-social-link linkedin / flex items-center justify-center / w-10 h-10 rounded-full"
                      href="https://www.linkedin.com/company/comparecredit365/about/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="w-5 h-5">
                        <Image
                          alt="linkedin white logo"
                          src="/static/icons/linkedin-white.svg"
                          height={20}
                          width={20}
                          style={nextImageHelper.responsive}
                          sizes="100vw"
                        />
                      </div>
                    </a>
                  </li>
                  <li>
                    <a
                      className="c-social-link instagram / flex items-center justify-center / w-10 h-10 rounded-full"
                      href="https://www.instagram.com/compare.credit/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="w-6 h-6">
                        <Image
                          alt="Instagram white logo"
                          src="/static/icons/instagram-logomark-white.svg"
                          height={20}
                          width={20}
                          style={nextImageHelper.responsive}
                          sizes="100vw"
                        />
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="c-footer__category / w-1/2 sm:w-1/3 md:w-1/2 / mb-12 md:mb-8 lg:mb-0 / pl-1 sm:pl-0 sm:pr-2 lg:pr-0 / lg:hidden">
              <p className="font-bold mb-2">Connect</p>
              <ul className="flex flex-row flex-wrap lg:flex-nowrap">
                <li className="mr-2 mb-2 xs:mb-0 lg:mr-1">
                  <a
                    className="c-social-link facebook / flex / items-center justify-center / w-10 h-10 rounded-full"
                    href="https://www.facebook.com/CompareCredit365/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="w-6 h-6">
                      <Image
                        alt="facebook white logo"
                        src="/static/icons/facebook-white.svg"
                        height={24}
                        width={24}
                        style={nextImageHelper.responsive}
                        sizes="100vw"
                      />
                    </div>
                  </a>
                </li>
                <li className="mr-2 mb-2 xs:mb-0 lg:mr-1">
                  <a
                    className="c-social-link linkedin / flex items-center justify-center / w-10 h-10 rounded-full"
                    href="https://www.linkedin.com/company/comparecredit365/about/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="w-5 h-5">
                      <Image
                        alt="linkedin white logo"
                        src="/static/icons/linkedin-white.svg"
                        height={20}
                        width={20}
                        style={nextImageHelper.responsive}
                        sizes="100vw"
                      />
                    </div>
                  </a>
                </li>
                <li>
                  <a
                    className="c-social-link instagram / flex items-center justify-center / w-10 h-10 rounded-full"
                    href="https://www.instagram.com/compare.credit/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="w-6 h-6">
                      <Image
                        alt="Instagram white logo"
                        src="/static/icons/instagram-logomark-white.svg"
                        height={20}
                        width={20}
                        style={nextImageHelper.responsive}
                        sizes="100vw"
                      />
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="flex flex-wrap w-full sm:flex-wrap sm:items-end">
            <hr className="w-full mb-10 border-white opacity-25" />
            <div className="w-full flex-grow">
              <p className="font-bold mb-2">{props.generalDisclaimer.name}</p>
              <PortableText
                value={props.generalDisclaimer.content}
                components={{
                  block: { normal: Normal },
                }}
              />
              <p
                id="editorial-disclosure-footer"
                className="editorial-disclosure-footer / font-bold mb-2"
              >
                {props.editorialNote.name}
              </p>
              <PortableText
                value={props.editorialNote.content}
                components={{
                  block: { normal: Normal },
                }}
              />
              <p className="font-bold mb-2">
                {props.compareCreditAwardsProgram.name}
              </p>
              <PortableText
                value={props.compareCreditAwardsProgram.content}
                components={{
                  block: { normal: Normal },
                  marks: { link: AwardsLink },
                }}
              />
            </div>
            <div className="w-full / md:w-[40%] lg:w-[50%] / md:pr-4 lg:pr--0">
              <p className="font-bold mb-6 flex items-center">
                <button
                  className="w-8 mr-4"
                  onClick={(e) => {
                    if (e.detail == 2) {
                      window.alert(analytics.user().anonymousId())
                    }
                  }}
                >
                  <Image
                    alt="compare credit logomark"
                    src="/static/logos/compare-credit_logomark.svg"
                    height={37}
                    width={32}
                    style={nextImageHelper.responsive}
                    sizes="100vw"
                  />
                </button>
                <span className="mt-2">
                  &reg;{getCurrentYear()} Compare Credit
                </span>
              </p>
              <ul className="flex flex-wrap font-bold leading-snug">
                <li className="mb-2 mr-6">
                  <FooterLink
                    text="Credit Card News"
                    href={pageRoutes['credit-cards']}
                  />
                </li>
                <li className="mb-2 mr-6">
                  <FooterLink text="About Us" href={pageRoutes['about']} />
                </li>

                <li className="mb-2 mr-6">
                  <Link href={pageRoutes['terms-conditions']}>
                    <span className="hover:text-primary-bright cursor-pointer / transition-all">
                      Terms &amp; Conditions
                    </span>
                  </Link>
                </li>
                <li className="mb-2">
                  <Link href={pageRoutes['privacy']}>
                    <span className="hover:text-primary-bright cursor-pointer / transition-all">
                      Privacy Policy
                    </span>
                  </Link>
                </li>
              </ul>
            </div>

            <hr className="w-full border-white opacity-25 / mt-8 mb-10 / md:hidden" />
            <div className="flex w-full / md:w-[60%] lg:w-[50%]">
              <ul className="flex flex-row w-full items-center justify-around">
                <li className="">
                  <a
                    href="https://www.inc.com/profile/compare-credit"
                    target="_blank"
                    rel="noreferrer"
                    aria-label="visit inc 5000"
                    className="inline-block / w-16 xs:w-18 sm:w-[5.5rem] md:w-[5rem] lg:w-[6rem]"
                  >
                    <Image
                      src="/static/as-seen-on/badge-inc-5000.png"
                      height={72}
                      width={72}
                      alt="INC 5000"
                      aria-hidden={true}
                      style={nextImageHelper.responsive}
                      sizes="100vw"
                    />
                  </a>
                </li>
                <li className="">
                  <span className="inline-block / w-[5rem] xs:w-[6rem] sm:w-[7.5rem] md:w-[6rem] lg:w-[7rem] xl:w-[7.5rem]">
                    <Image
                      alt="norton white logo"
                      src="/static/logos/norton-white.svg"
                      height={77}
                      width={144}
                      style={nextImageHelper.responsive}
                      sizes="100vw"
                    />
                  </span>
                </li>
                <li className="">
                  <span className="inline-block / w-[5rem] xs:w-[6rem] sm:w-[7.5rem] md:w-[6rem] lg:w-[7rem] xl:w-[7.5rem]">
                    <Image
                      alt="bbb base white logo"
                      src="/static/logos/bbb-base-white.svg"
                      height={33}
                      width={94}
                      style={nextImageHelper.responsive}
                      sizes="100vw"
                    />
                  </span>
                </li>
                <li className="">
                  <span className="inline-block / w-[3rem] xs:w-[3.5rem] sm:w-[4rem] md:w-[3.5rem] lg:w-[4rem] xl:w-[4.5rem]">
                    <Image
                      alt="equal housing opportunity white logo"
                      src="/static/logos/equal-housing-opportunity-w.svg"
                      height={144}
                      width={144}
                      style={nextImageHelper.responsive}
                      sizes="100vw"
                    />
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <style jsx>{`
        @media (min-width: 1024px) {
          .c-footer__category {
            width: 18.5%;
          }
          .c-footer__category:first-child {
            width: 26%;
          }
        }
        @media (min-width: 1152px) {
          .c-footer__category {
            width: 18%;
          }
          .c-footer__category:first-child {
            width: 22%;
          }
        }

        .c-social-link {
          border: 1px solid rgba(255, 255, 255, 0.15);
        }
        .c-social-link.facebook:hover {
          background-color: #4267b2;
          border-color: #4267b2;
        }
        .c-social-link.twitter:hover {
          background-color: #1da1f2;
          border-color: #1da1f2;
        }
        .c-social-link.linkedin:hover {
          background-color: #0077b5;
          border-color: #0077b5;
        }
        .c-social-link.instagram:hover {
          background-color: #c13584;
          border-color: #c13584;
        }

        .c-img-trust--eho {
          width: 3.5rem;
        }
        @media (min-width: 480px) {
          .c-img-trust--eho {
            width: 4rem;
          }
        }
      `}</style>
    </footer>
  )
}

Footer.defaultProps = {
  generalDisclaimer: last(
    disclosure.filter((d) => d.name === 'General Disclaimer'),
  ),
  editorialNote: last(disclosure.filter((d) => d.name === 'Editorial Note')),
  compareCreditAwardsProgram: last(
    disclosure.filter((d) => d.name === 'CompareCredit Awards Program'),
  ),
}
