import React from 'react'
import { NextSeo } from 'next-seo'
import { v4 as uuid } from 'uuid'

import {
  CardPanel,
  ExpertReview,
  LayoutMain,
  PopularArticles,
  RelatedCards,
} from '../'
import { CardNav } from '../entity-panel/sections'
import { CompareCredit } from '../../../types/compare-credit'
import { Breadcrumbs } from '../entity-panel/sections/'
import { TopCategories } from './top-categories'
import { Header } from './header'
import { FaqCard } from './faq'
import { CardReviewLinkedData } from './json-ld'
import { useFetchCardsSlug } from '../../utils'
import { LoadingSkeleton } from './loading-skeleton'
import { CreditCardLinkedData } from '../linked-data/card'

export function CardDetailsTemplate(props: CompareCredit.CardDetailProps) {
  const {
    defaultCard,
    relatedSlugs,
    slug,
    issuersWithCategoryPage = [],
    categoriesThatReferenceCard = [],
  } = props

  const { cards } = useFetchCardsSlug([slug])
  const { cards: relatedCards } = useFetchCardsSlug(relatedSlugs)
  const card = cards[0]
  const orderId = React.useMemo<string>(() => uuid(), [])
  const categoryId = '40'
  const externalId = '40'

  const issuerHasCategoryPage = !!issuersWithCategoryPage.find(
    (issuer) => issuer === defaultCard.issuer.slug.current,
  )

  const { expertReviewBody, expertReviewHighlight, expertReviewRating } =
    defaultCard as CompareCredit.FormattedCard | CompareCredit.NonPaidCard

  const hasExpertReview =
    expertReviewBody && expertReviewHighlight && expertReviewRating

  const breadCrumbData = [
    {
      text: 'Credit Cards',
      href: `/credit-cards/best/`,
    },
    { text: defaultCard.name },
  ]

  if (issuerHasCategoryPage) {
    breadCrumbData.splice(1, 0, {
      text: defaultCard.issuer.name,
      href: `/credit-cards/${defaultCard.issuer.slug.current}`,
    })
  }

  return (
    <>
      <LayoutMain>
        <NextSeo
          title={defaultCard.seoTitle}
          description={defaultCard.seoDescription || ''}
          openGraph={{
            title: props.seoTitle,
            description: props.seoDescription || '',
            site_name: 'Compare Credit',
            type: 'website',
            images: [
              {
                url: `${props.defaultCard.cardArt}`,
                alt: 'Og Image Alt',
              },
            ],
          }}
          twitter={{
            handle: '@Compare__Credit',
            site: '@Compare__Credit',
            cardType: 'summary',
          }}
          facebook={{
            appId: '139213170647191',
          }}
        />
        <Breadcrumbs items={breadCrumbData} />
        {!card && <LoadingSkeleton n={1} />}
        {card && (
          <>
            <Header
              card={
                card as CompareCredit.FormattedCard | CompareCredit.NonPaidCard
              }
              externalId={externalId}
              orderId={orderId}
            />
            <CardPanel
              categoryId={categoryId}
              externalId={externalId}
              entity={
                card as CompareCredit.FormattedCard | CompareCredit.NonPaidCard
              }
              featured={false}
              propsOrderId={orderId}
              view="details"
            />
            <section className="mx-auto max-w-4xl / mb-2 pb-2">
              {categoriesThatReferenceCard.length > 0 && (
                <TopCategories categories={categoriesThatReferenceCard} />
              )}
              {hasExpertReview && (
                <ExpertReview
                  card={
                    card as
                      | CompareCredit.FormattedCard
                      | CompareCredit.NonPaidCard
                  }
                  externalId={externalId}
                  orderId={orderId}
                />
              )}
              {(card as CompareCredit.FormattedCard).faqCard.length > 0 && (
                <section className="w-full / mx-auto / pt-4 pb-12">
                  <FaqCard card={card as CompareCredit.FormattedCard} />
                </section>
              )}
              <CardNav
                key={card._id}
                card={
                  card as
                    | CompareCredit.FormattedCard
                    | CompareCredit.NonPaidCard
                }
                categoryId={categoryId}
                externalId={externalId}
                orderId={orderId}
              />
            </section>
            <RelatedCards
              categoryId={categoryId}
              externalId={externalId}
              relatedCards={relatedCards}
            />
            <PopularArticles
              articles={(card as CompareCredit.FormattedCard).popularArticles}
            />
          </>
        )}
      </LayoutMain>
      <CreditCardLinkedData card={defaultCard} />
      <CardReviewLinkedData card={defaultCard} />
    </>
  )
}
