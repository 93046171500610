import React from 'react'
import Image from 'next/image'
import Link from 'next/link'
import { PortableText } from '@portabletext/react'
import { isMobileOnly } from 'react-device-detect'
import { useStatsigClient } from '@statsig/react-bindings'

import {
  CallToApply,
  CardArt,
  LinkToRedirect,
  StarRatingEditor,
  UserContext,
  blockSerializers,
} from '..'
import { CompareCredit } from '../../../types/compare-credit'
import { firstIsVowel, supify, nextImageHelper } from '../../utils'
import {
  clickRatesAndFees,
  exitModalApplyNowClicked,
  exitModalClosed,
  exitModalShown,
} from '../../clients/segment'
import { SecureSite } from '../secure-site'

export const ExitModalCard = (props: {
  exitModalCardTitle: string
  exitModalCardTitleBrightBlue?: string
  exitModalCardSubTitle?: string
  exitModalCardRatingsCount?: boolean
  exitModalCardProduct: CompareCredit.FormattedCard | CompareCredit.NonPaidCard
  orderId: string
  categoryId: string
  externalId: string
  exitModalContent: any
  cardOrderIds: Record<string, string>
  referencedCards: null | Record<string, CompareCredit.Entities>
}) => {
  const {
    exitModalCardTitle,
    exitModalCardTitleBrightBlue,
    exitModalCardSubTitle,
    exitModalCardRatingsCount,
    exitModalCardProduct,
    orderId,
    categoryId,
    externalId,
    exitModalContent,
    cardOrderIds,
    referencedCards,
  } = props

  const { clientLocation } = React.useContext(UserContext)
  const { logEvent } = useStatsigClient()

  const setHideModal = () => setShowModal(false)

  const [showModal, setShowModal] = React.useState(true)

  const displayModalEvents = () => {
    exitModalShown({ sku: exitModalCardProduct.slug })
    logEvent('modal_shown')
  }

  //Modal Shown
  showModal && displayModalEvents()

  function AttributeRenderer<K extends keyof CompareCredit.Entity>(attrProps: {
    value: { attribute: K }
  }) {
    return (
      <span>
        {' '}
        {exitModalCardProduct[attrProps.value.attribute] as React.ReactNode}
      </span>
    )
  }
  const handleClick = () => {
    exitModalApplyNowClicked({
      sku: exitModalCardProduct.slug,
      order_id: orderId,
    })
    logEvent('modal_apply_now_clicked')
  }

  const handleModalClose = () => {
    setHideModal()
    exitModalClosed({
      sku: exitModalCardProduct.slug,
    })
    logEvent('modal_exited')
  }

  // close modal with esc key
  React.useEffect(() => {
    const closeModal = (e: any) => {
      if (e.keyCode === 27) {
        handleModalClose()
      }
    }
    window.addEventListener('keydown', closeModal)
    return () => window.removeEventListener('keydown', closeModal)
  }, [])

  return (
    <div
      className={showModal ? 'block' : 'hidden'}
      aria-hidden={showModal ? 'false' : 'true'}
    >
      <div
        className={`c-modal c-modal-card / fixed w-full h-full top-0 left-0 / z-80 / px-4 / ${
          showModal ? 'modal-in' : ''
        }`}
      >
        {exitModalCardProduct && (
          <div className="c-modal__container c-modal-card__container / relative top-0 / max-w-lg w-full / mx-auto mb-6 mt-14 / shadow-lg rounded-lg z-80 / overflow-visible">
            <div
              className="c-modal__content c-modal-card__content / relative / flex flex-col items-stretch justify-center / w-full / mx-auto / rounded-lg shadow-lg"
              tabIndex={-1}
              role="dialog"
            >
              <button
                className="c-modal__close-btn / absolute top-0 right-0 z-2 / w-8 h-8 / mt-2 mr-2 / cursor-pointer / opacity-25 / hover:opacity-75 focus:outline-none / transition-all--25"
                onClick={() => {
                  handleModalClose()
                }}
                aria-label="close modal"
                type="button"
              >
                <Image
                  className="w-8"
                  alt="close icon"
                  role="presentation"
                  src="/static/icons/icon-close-circle-black.svg"
                  aria-hidden={true}
                  fill
                  sizes="100vw"
                />
              </button>
              <div className="c-modal__body  c-modal-card__body / relative z-1 / rounded-lg">
                <div className="relative z-2 / px-4 xs:px-8 sm:px-10 pb-4 sm:pb-6 / text-center">
                  <div className="mb-4 / border-b-3 border-tetriary">
                    <div className="c-card-art / w-48 xs:w-56 / mx-auto mb-1.5 xs:mb-2 -mt-12 / text-center / flex items-center justify-center rounded-lg shadow-lg">
                      <div className="relative / w-full / rounded-lg shadow">
                        {exitModalCardProduct._type === 'card' ? (
                          <LinkToRedirect
                            externalId={externalId}
                            linkParams={
                              exitModalCardProduct.applyNowLinkParameters
                            }
                            orderId={orderId}
                            query={undefined}
                            slug={exitModalCardProduct.slug}
                          >
                            <button
                              className="relative flex w-full rounded-lg overflow-hidden"
                              onClick={handleClick}
                            >
                              <span className="c-card-art__content / absolute / flex flex-wrap content-center items-center / z-10 left-0 / w-full h-full text-white">
                                <span className="c-card-art__icon / w-full mb-0 h-8">
                                  <span className="inline-block w-8">
                                    <Image
                                      height={32}
                                      width={32}
                                      alt="icon lock"
                                      src="/static/icons/icon-lock-round-w.svg"
                                      style={nextImageHelper.responsive}
                                      sizes="100vw"
                                    />
                                  </span>
                                </span>
                                <span className="w-full font-semibold">
                                  Apply Now
                                </span>
                              </span>

                              <CardArt
                                cardArt={exitModalCardProduct.cardArt}
                                categoryId={categoryId}
                                externalId={externalId}
                                imgClasses="relative / z-0 / w-full h-full object-cover top-0"
                                customCodeSnippets={
                                  exitModalCardProduct.customCodeSnippets
                                }
                                issuer={
                                  exitModalCardProduct.issuer.slug.current
                                }
                                name={exitModalCardProduct.name}
                                orderId={orderId}
                                slug={exitModalCardProduct.slug}
                                type={exitModalCardProduct._type}
                                _rev={null}
                              />
                            </button>
                          </LinkToRedirect>
                        ) : (
                          <Link href={exitModalCardProduct.moreInfoLink}>
                            <button
                              className="relative flex w-full rounded-lg overflow-hidden"
                              onClick={handleClick}
                            >
                              <span className="c-card-art__content / absolute / flex flex-wrap content-center items-center / z-10 left-0 / w-full h-full text-white">
                                <span className="w-full font-semibold">
                                  Learn More
                                </span>
                              </span>

                              <CardArt
                                cardArt={exitModalCardProduct.cardArt}
                                categoryId={categoryId}
                                externalId={externalId}
                                imgClasses="relative / z-0 / w-full h-full object-cover top-0"
                                customCodeSnippets={
                                  exitModalCardProduct.customCodeSnippets
                                }
                                issuer={
                                  exitModalCardProduct.issuer.slug.current
                                }
                                name={exitModalCardProduct.name}
                                orderId={orderId}
                                slug={exitModalCardProduct.slug}
                                type={exitModalCardProduct._type}
                                _rev={null}
                              />
                            </button>
                          </Link>
                        )}
                      </div>
                    </div>
                    <h3 className="c-modal-card__card-name / mx-auto mb-0.5 / leading-tight text-sm xs:text-base">
                      {exitModalCardProduct._type === 'card' ? (
                        <LinkToRedirect
                          externalId={externalId}
                          linkParams={
                            exitModalCardProduct.applyNowLinkParameters
                          }
                          orderId={orderId}
                          query={undefined}
                          slug={exitModalCardProduct.slug}
                        >
                          <button
                            className="inline-block / w-full / text-center text-primary-mid leading-snug font-bold / hover:text-primary-bright cursor-pointer / transition-all"
                            onClick={handleClick}
                            dangerouslySetInnerHTML={{
                              __html: supify(exitModalCardProduct.name),
                            }}
                          />
                        </LinkToRedirect>
                      ) : (
                        <Link href={exitModalCardProduct.moreInfoLink}>
                          <button
                            className="inline-block / w-full / text-center text-primary-mid leading-snug font-bold / hover:text-primary-bright cursor-pointer / transition-all"
                            onClick={handleClick}
                            dangerouslySetInnerHTML={{
                              __html: supify(exitModalCardProduct.name),
                            }}
                          />
                        </Link>
                      )}
                    </h3>

                    <div
                      className={`c-modal-card__rating flex items-center justify-center / w-full ${
                        exitModalCardRatingsCount &&
                        'c-product-rating--hide-review-count'
                      }`}
                    >
                      <StarRatingEditor
                        productSlug={exitModalCardProduct.slug}
                        stars={exitModalCardProduct.expertReviewRating}
                      />
                    </div>
                  </div>
                  {exitModalCardSubTitle && (
                    <p className="c-modal-card__sub-title / mb-1 font-bold text-slate-500 leading-tight tracking-wide text-sm">
                      {exitModalCardSubTitle}
                    </p>
                  )}

                  <p
                    className={`c-modal-card__title / font-bold text-primary text-center leading-tight text-2xl xxs:text-3xl xs:text-4xl ${
                      exitModalContent ? 'mb-2' : 'mb-2.5'
                    }`}
                  >
                    {exitModalCardTitle}{' '}
                    {exitModalCardTitleBrightBlue && (
                      <span className="c-modal-card__title-highlight / text-primary-bright">
                        {exitModalCardTitleBrightBlue}
                      </span>
                    )}
                  </p>

                  {exitModalContent && (
                    <div className="c-modal-card__exit-content / mb-3.5 / xs:text-lg text-slate-700 text-center leading-snug text-sm sm:text-base">
                      <PortableText
                        value={exitModalContent as any}
                        components={{
                          ...blockSerializers({
                            cardOrderIds,
                            categoryId,
                            externalId,
                            referencedCards,
                          }),
                          types: {
                            cardAttributeTips: AttributeRenderer,
                          },
                        }}
                      />
                    </div>
                  )}

                  <p className="c-modal-card__cta / mx-auto text-center">
                    {exitModalCardProduct._type === 'card' ? (
                      <LinkToRedirect
                        externalId={externalId}
                        linkParams={exitModalCardProduct.applyNowLinkParameters}
                        orderId={orderId}
                        query={undefined}
                        slug={exitModalCardProduct.slug}
                      >
                        <button
                          className="c-btn c-btn--primary max-w-none text-xl mb-2 leading-tight"
                          onClick={handleClick}
                        >
                          Apply Now
                          <span className="c-btn__icon / relative w-5 h-5 / ml-2 / align-middle">
                            <Image
                              alt="lock"
                              src="/static/icons/icon-lock-round-w.svg"
                              aria-hidden="true"
                              role="presentation"
                              fill
                              sizes="100vw"
                            />
                          </span>
                        </button>
                      </LinkToRedirect>
                    ) : (
                      <Link href={exitModalCardProduct.moreInfoLink}>
                        <button
                          className="c-btn c-btn--primary max-w-none text-xl mb-2 leading-tight"
                          onClick={handleClick}
                        >
                          Learn More
                        </button>
                      </Link>
                    )}
                  </p>
                  {exitModalCardProduct._type === 'card' && (
                    <p className="c-modal-card__secure-text / text-fs13 text-slate-500 / text-center leading-tight / mb-2">
                      <SecureSite
                        issuerName={exitModalCardProduct.issuer.name}
                        issuerSecureSite={exitModalCardProduct.issuerSecureSite}
                      />
                    </p>
                  )}

                  <div className="c-modal-card__call">
                    {exitModalCardProduct._type === 'card' &&
                      exitModalCardProduct.phoneNumber &&
                      isMobileOnly &&
                      clientLocation?.country === 'US' && (
                        <div className="w-full / pt-1">
                          <p className="mb-3">
                            <CallToApply
                              classes="text-xl"
                              entity={exitModalCardProduct}
                            />
                          </p>

                          <p className="text-xs text-slate-600 / text-center / -mt-1 mb-4 / md:self-start w-full max-w-xs leading-4">
                            with{' '}
                            {firstIsVowel(exitModalCardProduct.issuer.name)
                              ? 'an'
                              : 'a'}{' '}
                            {exitModalCardProduct.issuer.name} representative
                          </p>
                        </div>
                      )}
                  </div>

                  {exitModalCardProduct._type === 'card' &&
                    exitModalCardProduct.ratesAndFees &&
                    exitModalCardProduct.ratesAndFees !== 'N/A' && (
                      <p className="c-modal-card__rates-fees / text-primary-bright-dark text-xs w-full text-center mb-2 / hover:text-primary-bright">
                        <a
                          href={exitModalCardProduct.ratesAndFees}
                          onClick={() => {
                            clickRatesAndFees({
                              applyNowLink: exitModalCardProduct.applyNowLink,
                              component: 'Exit Modal Card',
                              name: exitModalCardProduct.name,
                              url: window.location.href,
                            })
                          }}
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          Rates &amp; Fees
                        </a>
                      </p>
                    )}
                </div>
                <div className="c-modal__mask / absolute top-0 left-0 z-1 / w-full / rounded-t-lg overflow-hidden">
                  <Image
                    alt="background mask"
                    src="/static/images/bg-mask-wave-top-white.png"
                    aria-hidden={true}
                    fill
                    sizes="100vw"
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        <div
          aria-label="close modal"
          className={`c-modal__bg / fixed top-0 left-0 / w-full h-full ${
            showModal ? '' : 'hidden'
          }`}
          onClick={() => {
            handleModalClose()
          }}
          onKeyDown={() => handleModalClose()}
          role="button"
          tabIndex={0}
        />
      </div>
      <style jsx>
        {`
          /*===== MODAL =====*/
          .c-modal__banner {
            background-image: url(/static/images/amazon-boxes-doorstep.jpg);
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
          }
          .c-modal__body {
            background: linear-gradient(266.06deg, #f1faf2 50%, #f0faff 95%);
          }
          .c-modal__mask {
            height: 75%;
          }
          .c-modal-card__exit-content b,
          .c-modal-card__exit-content strong {
            color: #052f5e;
          }
        `}
      </style>
    </div>
  )
}
