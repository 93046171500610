import React from 'react'
import every from 'lodash/fp/every'
import some from 'lodash/fp/some'
import Image from 'next/image'
import { useStatsigClient } from '@statsig/react-bindings'
import { v4 as uuid } from 'uuid'

import { UserContext } from '../'
import { CompareCredit } from '../../../types/compare-credit'
import { nextImageHelper } from '../../utils'
import { CardCartTile } from './card-cart-tile'
import { RecommendedCard } from './recommended-card'
import cn from 'utils/classnames'

export function CardCart() {
  const { comparisonCart, recommendedCard, updateComparisonCart } =
    React.useContext(UserContext)
  const { logEvent } = useStatsigClient()
  const [cartMenu, setCartMenu] = React.useState(false)
  const [recCardIsInCart, setRecCardIsInCart] = React.useState(false)
  const orderId = React.useMemo<string>(() => uuid(), [])
  const [isPrime, setIsPrime] = React.useState(false)

  React.useEffect(() => {
    if (!cartMenu) {
      window.removeEventListener('keyup', handleKeyUp)
    } else {
      window.addEventListener('keyup', handleKeyUp)
    }
  }, [cartMenu])

  function handleKeyUp(event: any) {
    switch (event.key) {
      case 'Escape':
        setCartMenu(false)
        break
    }
  }

  React.useEffect(() => {
    const inCart = some(
      (obj: CompareCredit.FormattedCard) => obj.name === recommendedCard?.name,
      comparisonCart,
    )
    setRecCardIsInCart(inCart)
  }, [comparisonCart.length, recommendedCard?.name])

  React.useEffect(() => {
    const cardsArePrime = every(
      (obj: CompareCredit.FormattedCard) => obj.targetCreditRange === 1,
      comparisonCart,
    )
    setIsPrime(cardsArePrime)
  }, [comparisonCart.length])

  // Compare Cart Events
  const handleCompareCartToggleCards = () => {
    setCartMenu((state) => !state)
    logEvent('compare_cart_toggle_cards')
  }
  const handleCompareCartReset = () => {
    updateComparisonCart('reset')
    logEvent('compare_cart_reset')
  }
  const handleCompareCartCompareBtn = () => {
    logEvent('compare_cart_compare_button')
  }
  const handleCompareCartCollapseBg = () => {
    setCartMenu((state) => !state)
    logEvent('compare_cart_collapse_cards_bg')
  }

  return (
    <div
      className={cn(
        'c-cart / fixed left-0 / flex w-full / sm:ml-21 sm:mb-21 / transition-all--25 z-70',
        {
          'is-open': comparisonCart.length > 0,
          invisible: comparisonCart.length == 0,
        },
      )}
      aria-hidden={comparisonCart.length > 0 ? 'false' : 'true'}
    >
      <div className="relative z-20 / w-full">
        <div className="c-cart__row / relative z-20 / flex content-start items-center justify-start / w-full / px-4 py-2 lg:pr-4 lg:pl-5 / bg-white">
          <button
            className="c-cards__mobile-ui / relative / w-16 mr-4 lg:mr-6 / transition-all"
            aria-expanded={cartMenu ? true : false}
            onClick={handleCompareCartToggleCards}
            aria-controls="card-cart"
          >
            <div className="h-auto w-16">
              <Image
                alt="credit card icon"
                role="presentation"
                aria-hidden="true"
                height={41}
                width={64}
                src="/static/icons/icon-card-gray.svg"
                style={nextImageHelper.responsive}
                sizes="100vw"
              />
            </div>
            <span className="c-cards__count / flex items-center justify-center / absolute / w-6 h-6 / text-white text-fs13 text-center / bg-primary-bright rounded-full">
              {comparisonCart.length}{' '}
              <span className="sr-only">credit cards in your cart</span>
            </span>
          </button>
          <a
            className="c-btn c-btn--ghost / cursor-pointer sm:shrink-0 relative h-10 w-40 px-0 py-2 / mx-0 xs:mr-2 lg:mr-3 / transition-all"
            href="/credit-cards/comparison"
            onClick={handleCompareCartCompareBtn}
          >
            Compare Cards
          </a>
          <button
            className="w-16 xs:mr-2 lg:mr-3 px-2 / text-gray-600 text-fs13 / hover:text-primary-bright / transition-all"
            type="button"
            aria-label="Remove all of your cards from your cart"
            onClick={handleCompareCartReset}
          >
            Reset
          </button>
        </div>
        <div
          id="card-cart"
          className={cn('c-cart__menu / absolute / w-full / bg-white', {
            'is-open': cartMenu,
          })}
          aria-hidden={cartMenu ? 'false' : 'true'}
        >
          <div className="c-cart__menu-inner / relative overflow-scroll">
            <div className="sm:flex sm:flex-row sm:flex-nowrap / w-full">
              {comparisonCart.length > 0 &&
                comparisonCart.map((card: CompareCredit.FormattedCard) => (
                  <CardCartTile
                    comparisonCart={comparisonCart}
                    updateComparisonCart={updateComparisonCart}
                    card={card}
                    recCard={recommendedCard}
                    key={card.slug}
                    isPrime={isPrime}
                    recCardIsInCart={recCardIsInCart}
                    orderId={orderId}
                    categoryId="704"
                    externalId="704"
                  />
                ))}
              {comparisonCart.length > 0 && recommendedCard && (
                <RecommendedCard
                  recCard={recommendedCard}
                  ribbonText={recommendedCard.ribbonText}
                  comparisonCart={comparisonCart}
                  isPrime={isPrime}
                  recCardIsInCart={recCardIsInCart}
                  orderId={orderId}
                  categoryId="705"
                  externalId="705"
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <button
        className={cn(
          'c-cart__bg / fixed top-0 left-0 z-10 / w-full h-full opacity-50',
          {
            'is-open': cartMenu && comparisonCart.length > 0,
          },
        )}
        tabIndex={0}
        aria-label="close modal"
        onClick={handleCompareCartCollapseBg}
      />
      <style jsx>{`
        /*===== CART =====*/
        .c-cart {
          bottom: -20rem;
        }

        // OPEN: When a card is added
        .c-cart.is-open {
          bottom: 0;
        }

        .c-cart.is-open-fulltime {
          bottom: 0 !important;
          opacity: 1 !important;
          visibility: visible !important;
        }

        .c-cart__alert {
          bottom: -100%;
        }

        .c-cards__mobile-ui:hover .c-cards__count {
          background-color: #0047ab;
        }

        /*===== IS-OPEN =====*/
        .c-cart__alert.is-open {
          top: inherit;
          bottom: 100%;
        }

        .c-cart__row {
          z-index: 3;
        }
        /*===== MENU =====*/
        .c-cart__menu {
          z-index: 1;
        }
        .c-cart__menu-inner {
          max-height: calc(100vh - (4rem + 57px));
        }
        .c-cart__bg {
          background-color: rgba(2, 22, 45, 100);
          opacity: 0;
          visibility: hidden;
          transform: translateY(100vh);
        }

        .c-cart__menu {
          bottom: 100%;
          box-shadow: 0 -1px 10px 0 rgba(0, 0, 0, 0.15);
          transition-property: all;
          transition-duration: 0.5s;
          transform: translateY(50vh);
          visibility: hidden;
          opacity: 0;
        }
        /*===== IS-OPEN =====*/

        .c-cart__menu.is-open {
          transform: translateY(0);
          visibility: visible;
          opacity: 1;
        }
        .c-cart__bg.is-open {
          transform: translateY(0);
          opacity: 0.5;
          visibility: visible;
        }

        /*===== IS-CLOSED =====*/
        .c-cart.is-closed .c-cart__menu {
          transform: translateY(50vh) !important;
          visibility: hidden !important;
          opacity: 0 !important;
        }
        .c-cart.is-closed .c-cart__bg {
          transform: translateY(100vh);
          opacity: 0 !important;
          visibility: hidden !important;
        }
      `}</style>
    </div>
  )
}
