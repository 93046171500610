import * as React from 'react'
import path from 'lodash/fp/path'
import Image from 'next/image'
import Link from 'next/link'
import { v4 as uuid } from 'uuid'

import { useNavData } from './navItems'
import { BackArrow, Forward } from '../icons'
import { NavItem } from './types'
import { LabelAd } from '../label-ad'
import { CardArt } from '../card-art'
import { StarRatingEditor } from '../star-rating-editor'
import { LinkToRedirect } from '../'
import { nextImageHelper, supify } from '../../utils'
import { UserContext } from '../UserContext'
import { SecureSite } from '../secure-site'
import cn from 'utils/classnames'
import { clickRatesAndFees } from '../../clients/segment'

export default function MobileNav(props: {
  alert: {
    show: boolean
    height: number
  }
  show: boolean
  selectedSubMenu: string | null
  setSelectedSubMenu: (arg0: string | null) => void
  setShowMobileNav: (arg0: boolean) => void
}) {
  const { alert, setShowMobileNav } = props
  const MenuItem = (props: NavItem) => {
    return (
      <Link href={props.href || '#'} className="inline-block w-full">
        <button
          onClick={() => setShowMobileNav(false)}
          className="flex items-center py-2 px-3 cursor-pointer text-gray-700 / rounded-sm / hover:bg-primary-light-bg hover:text-primary-bright / transition-all"
        >
          {props.icon && (
            <span
              className={`c-cate-icon ${props.icon} / inline-block / w-7 h-7 mr-3`}
            />
          )}
          <span className="font-extralight">{props.text}</span>
        </button>
      </Link>
    )
  }

  const navData = useNavData()
  const menuItems = Object.keys(navData)

  // DEV NOTE: Variable to display the nav upsell card
  const { navUpsellCard } = React.useContext(UserContext)

  const cardNavUpsellText =
    navUpsellCard?.navUpsellBannerText || 'Featured Card'

  const orderId = React.useMemo<string>(() => uuid(), [])

  return props.show ? (
    <div
      id="mobile-nav"
      className={`h-full w-full pt-16 lg:pt-20 / fixed lg:invisible overflow-scroll bg-white z-50 ${
        navUpsellCard ? 'pb-36' : ''
      }`}
      style={{
        marginTop: alert.show ? alert.height : 0,
      }}
    >
      {!props.selectedSubMenu ? (
        <>
          {menuItems.map((key: string, i: number) => {
            const item = navData[key]
            const { text, id, hasSections, href } = {
              id: key,
              text: item.text,
              href: item.href,
              hasSections: !!item.sections,
            }
            const Item = () => (
              <button
                type="button"
                onClick={() => {
                  if (hasSections) {
                    props.setSelectedSubMenu(id)
                  }
                }}
                className={cn(
                  'mx-auto flex py-2 px-5 w-full justify-between cursor-pointer items-center text-primary text-lg / hover:text-primary-bright hover:bg-primary-light-bg / transition-all',
                  {
                    'border-b': i + 1 !== menuItems.length,
                  },
                )}
              >
                <span className="flex items-center / font-bold">{text}</span>
                {hasSections && <Forward />}
              </button>
            )

            return hasSections ? (
              <Item key={i} />
            ) : (
              <Link key={i} href={href || '#'}>
                <Item />
              </Link>
            )
          })}
        </>
      ) : (
        <div>
          <button
            className="mx-auto w-full px-6 py-3 flex items-center cursor-pointer text-primary / transition-all"
            style={{ backgroundColor: '#F5FAFC' }}
            aria-label="Back to main menu"
            type="button"
            onClick={() => props.setSelectedSubMenu(null)}
          >
            <span className="mr-4 cursor-pointer">
              <BackArrow />
            </span>
            <span className="text-lg font-bold">
              {navData[props.selectedSubMenu].text}
            </span>
          </button>
          <div className="mx-auto w-full flex">
            {props.selectedSubMenu === 'credit-cards' && (
              <div className="mx-auto w-full pb-6 flex flex-col">
                <div className="p-2 text-sm">
                  {path(
                    ['credit-cards', 'sections', 'main', 'items'],
                    navData,
                  ).map((item: NavItem, i: number) => (
                    <MenuItem key={i} {...item} />
                  ))}
                </div>
              </div>
            )}
            {props.selectedSubMenu === 'card-issuer' && (
              <div className="mx-auto w-full pb-6 flex flex-col">
                <div className="p-2 text-sm">
                  {path(
                    ['card-issuer', 'sections', 'main', 'items'],
                    navData,
                  ).map((item: NavItem, i: number) => (
                    <MenuItem key={i} {...item} />
                  ))}
                </div>
              </div>
            )}
            {props.selectedSubMenu === 'credit-range' && (
              <div className="mx-auto w-full pb-6 flex flex-col">
                <div className="p-2 text-sm">
                  {path(
                    ['credit-range', 'sections', 'main', 'items'],
                    navData,
                  ).map((item: NavItem, i: number) => (
                    <MenuItem key={i} {...item} />
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {navUpsellCard ? (
        <div className="fixed bottom-0 left-0 / w-full / px-4 pb-2 / bg-white">
          <div className="w-full / pt-2 / border-t border-solid border-gray-300">
            <div className="c-card-upsell--mobile / flex flex-row flex-wrap / w-full max-w-sm / mx-auto / text-center">
              <div className="w-full mb-1 text-left">
                <p className="c-ribbon c-ribbon--slimmer c-ribbon--rt / relative inline-block / pl-2 pr-4 / text-xs font-bold text-primary leading-snug uppercase / bg-tetriary">
                  <span className="inline-block pt-0.5 overflow-hidden">
                    {cardNavUpsellText}
                  </span>
                </p>
              </div>
              <div className="flex flex-col / w-1/4">
                <div className="c-card-art__container / w-full / mx-auto mb-1">
                  <div className="c-card-art / relative / transition-25s">
                    <div className="c-card-art__container / relative / w-full / rounded-sm shadow overflow-hidden text-center">
                      {navUpsellCard && (
                        <LinkToRedirect
                          externalId="702"
                          linkParams={navUpsellCard.applyNowLinkParameters}
                          orderId={orderId}
                          slug={navUpsellCard.slug}
                        >
                          <div className="relative flex / w-full / overflow-hidden rounded-sm shadow z-0">
                            <span className="c-card-art__content / absolute / flex flex-wrap content-center items-center / z-10 left-0 / w-full h-full text-white lg:text-lg">
                              <span className="c-card-art__icon / w-full">
                                <span className="inline-block w-4 h-4">
                                  <Image
                                    alt="lock"
                                    src="/static/icons/icon-lock-round-w.svg"
                                    height={16}
                                    width={16}
                                    style={nextImageHelper.intrinsic}
                                  />
                                </span>
                              </span>
                              <span className="w-full font-semibold text-fs11 xs:text-fs13">
                                Apply Now
                              </span>
                            </span>
                            {navUpsellCard && (
                              <CardArt
                                _rev={navUpsellCard._rev}
                                categoryId="702"
                                externalId="702"
                                cardArt={navUpsellCard.cardArt}
                                imgClasses="relative z-0 w-full h-full object-cover top-0  bg-dark"
                                customCodeSnippets={
                                  navUpsellCard.customCodeSnippets
                                }
                                issuer={navUpsellCard.issuer.slug.current}
                                name={navUpsellCard.name}
                                orderId={orderId}
                                slug={navUpsellCard.slug}
                              />
                            )}
                          </div>
                        </LinkToRedirect>
                      )}
                    </div>
                  </div>
                </div>
                {navUpsellCard && (
                  <>
                    {navUpsellCard.ratesAndFees &&
                      navUpsellCard.ratesAndFees !== 'N/A' && (
                        <div>
                          <a
                            className="inline-block / w-full / text-xs text-center text-primary-bright leading-snug / hover:underline hover:text-primary-mid"
                            href={navUpsellCard.ratesAndFees}
                            onClick={() => {
                              clickRatesAndFees({
                                applyNowLink: navUpsellCard.applyNowLink,
                                component: 'Mobile Modal Leave Behind Tile',
                                name: navUpsellCard.name,
                                url: window.location.href,
                              })
                            }}
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            Rates &amp; Fees
                          </a>
                          <>
                            {navUpsellCard.issuer.slug.current ===
                              'american-express' && (
                              <p className="text-fs10 text-gray-600 / text-center / mb-3 md:mb-1 / w-full leading-4">
                                Terms Apply
                              </p>
                            )}
                          </>
                        </div>
                      )}
                  </>
                )}
              </div>
              <div className="w-3/4 / pl-2 xxs:pl-4">
                <p className="w-full / text-left text-sm sm:text-base leading-tight">
                  {navUpsellCard && (
                    <LinkToRedirect
                      externalId="702"
                      linkParams={navUpsellCard.applyNowLinkParameters}
                      orderId={orderId}
                      slug={navUpsellCard.slug}
                    >
                      <div
                        className="text-primary-mid font-semibold / hover:text-primary-bright"
                        dangerouslySetInnerHTML={{
                          __html: supify(navUpsellCard.name),
                        }}
                      />
                    </LinkToRedirect>
                  )}
                </p>
                <div className="relative / text-left">
                  {navUpsellCard && (
                    <StarRatingEditor
                      productSlug={navUpsellCard.slug}
                      stars={navUpsellCard.expertReviewRating}
                      positionTop={true}
                    />
                  )}
                </div>
                <div className="max-w-13rem">
                  <p>
                    {navUpsellCard && (
                      <LinkToRedirect
                        linkParams={navUpsellCard.applyNowLinkParameters}
                        orderId={orderId}
                        externalId="702"
                        slug={navUpsellCard.slug}
                      >
                        <div className="c-btn c-btn--primary / w-full / mb-1 py-1 / leading-tighter text-sm">
                          Apply Now
                          <span className="c-btn__icon / inline-block / w-4 h-4 ml-2">
                            <Image
                              alt="lock"
                              src="/static/icons/icon-lock-round-w.svg"
                              height={16}
                              width={16}
                              style={nextImageHelper.intrinsic}
                            />
                          </span>
                        </div>
                      </LinkToRedirect>
                    )}
                  </p>{' '}
                  <p className="text-xs text-gray-600">
                    {navUpsellCard ? (
                      <span>
                        <SecureSite
                          issuerName={navUpsellCard.issuer.name}
                          issuerSecureSite={navUpsellCard.issuerSecureSite}
                        />
                      </span>
                    ) : (
                      ``
                    )}
                  </p>
                </div>
              </div>
            </div>
            <div className="absolute bottom-[6px] right-[6px]">
              <LabelAd />
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  ) : null
}
