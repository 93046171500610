import * as Sentry from '@sentry/nextjs'
import React from 'react'
import { isMobile } from 'react-device-detect'
import prop from 'lodash/fp/prop'
import propOr from 'lodash/fp/propOr'
import { useExperiment } from 'utils/use-experiment-wrapper'
import { v4 as uuid } from 'uuid'

import { CardPanel, Modal, UserContext } from '../../'
import {
  CompareCredit,
  ProductDisplayType,
} from '../../../../types/compare-credit'

import { Card } from './card'
import ErrorBoundary from '../../error-boundary'
import { LoadingSkeleton } from './loading-skeleton'

import { formatPinnedProducts } from 'ssg/utils/format-pinned-products'
import mapEntityPositions from 'utils/mapEntityPositions'
import { pinEntities } from 'utils/pin-entities'
import { viewProductList } from 'clients/segment'
import { useIsUserOnBrave } from 'utils/useIsUserOnBrave'
import { useStatsigClient } from '@statsig/react-bindings'

const TipCardTileDefault = (props: {
  tip: {
    _key: string
    card: string
    cardSlug: string | CompareCredit.Slug
    content: {
      children: any
    }[]
    buttonText?: string
    linkText?: string
    tipContentBlockDisplay?: boolean
    tipContentBlockSelected?: any
  }
  cardOrderIds: Record<string, string>
  referencedCards: null | Record<string, CompareCredit.Entities>
  tag: CompareCredit.CategoryTag
  categoryTag: CompareCredit.CategoryTag
  idx: number
  recommendedCredit?: boolean
  modalTriggerPosition?: number
  exitModalRef: React.RefObject<any>
  productDisplay?: ProductDisplayType
  arrangementId?: string
}) => {
  const { clientLocation } = React.useContext(UserContext)
  const [modalOpen, setModalOpen] = React.useState(false)
  const [modalCard, setModalCard] =
    React.useState<CompareCredit.FormattedCard | null>(null)

  const { tip, tag, categoryTag, modalTriggerPosition, arrangementId } = props

  const externalId = props.tag ? props.tag.id : '00'
  const categoryId = props.categoryTag ? props.categoryTag.id : '00'

  const slug = propOr(tip.cardSlug, 'current', tip.cardSlug)

  const orderId = React.useMemo<string>(
    () => prop(tip.cardSlug as string, props.cardOrderIds) || uuid(),
    [tip.cardSlug, JSON.stringify(props.cardOrderIds)],
  )

  return (
    <>
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <>
          {modalCard ? (
            <CardPanel
              categoryId={categoryId}
              externalId={externalId}
              onClose={() => setModalOpen(false)}
              view="modal"
              featured={false}
              entity={modalCard}
              comparisonDisabled={true}
              propsOrderId={orderId}
              arrangementId={arrangementId}
            />
          ) : null}
        </>
      </Modal>
      <div className="c-modal z-50 / hidden">
        <style jsx>{`
          .c-modal {
            position: fixed;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(2, 22, 45, 0.8);
          }
        `}</style>
      </div>
      <ErrorBoundary key={tip._key}>
        {props.productDisplay === 'panel' &&
        props.referencedCards &&
        props.referencedCards[slug] ? (
          <CardPanel
            categoryId={categoryId}
            externalId={externalId}
            index={props.idx}
            position={props.idx + 1}
            entity={props.referencedCards[slug] as CompareCredit.FormattedCard}
            view="list"
            collapsed
            sortable
            arrangementId={arrangementId}
          />
        ) : (
          <Card
            exitModalRef={props.exitModalRef}
            clientLocation={clientLocation}
            modalOpen={modalOpen}
            setModalCard={setModalCard}
            setModalOpen={setModalOpen}
            tag={tag}
            categoryTag={categoryTag}
            tip={tip}
            cardOrderIds={props.cardOrderIds}
            referencedCards={props.referencedCards}
            position={props.idx + 1}
            modalTriggerPosition={modalTriggerPosition}
            recommendedCredit={props.recommendedCredit}
            arrangementId={arrangementId}
          />
        )}
      </ErrorBoundary>
    </>
  )
}

type CardTip = {
  _key: string
  _type: string
  card: string
  cardSlug: string
  content: any
  issuer: string
  sortPosition: number
  linkText?: string
  buttonText?: string
}

export const TipCardTileList = (data: {
  cardOrderIds: Record<string, string>
  exitModalRef: any
  exitModalMultiCardTipPosition?: number
}) => {
  const TipCardTileListRender = (props: {
    value: {
      cardTips: CompareCredit.Tip[]
      tag: CompareCredit.CategoryTag
      categoryTag: CompareCredit.CategoryTag
      style: string
      sort: string
      pinnedCardTips: CompareCredit.PinnedProduct[]
      recommendedCredit?: boolean
      exitModalRef?: any
      exitModalMultiCardTipPosition?: number
      productDisplay?: ProductDisplayType
      referencedCards: null | Record<string, CompareCredit.Entities>
      listClassName?: string
    }
  }) => {
    const [cardTipsToSort, setCardTipsToSort] = React.useState<any>([])
    const { cardOrderIds } = data
    const [cardTips, setCardTips] = React.useState<any[]>([])
    const [arrangementId, setArrangementId] = React.useState('')
    const [cards, setCards] = React.useState<any[]>([])
    const statsig = useStatsigClient()

    const { listClassName, sort, referencedCards } = props.value

    const cardSlugs = props.value.cardTips.map((cardTip) => cardTip.card.slug)

    React.useEffect(() => {
      setCards(cardSlugs.map((slug) => propOr([], slug, referencedCards)))
    }, [sort, JSON.stringify(referencedCards)])

    const attachSortPositionToCardTips = (
      cards: any[],
      cardTips: any[],
    ): CardTip[] => {
      const cardsMap = cards.reduce((acc: any, val: any) => {
        acc[val.slug] = val
        return acc
      }, {})

      const getIssuer = (card: any) => {
        if (card) {
          if (card._type === 'card' || card._type === 'NonPaidCard') {
            return card.issuer.slug.current
          }
          if (card._type === 'product') {
            return card.brand.slug.current
          }
        }

        return ''
      }

      return cardTips.map((cardTip: any) => {
        const card = cardsMap[cardTip.card.slug]
        const issuer = getIssuer(card)

        return {
          _key: cardTip._key,
          _type: cardTip._type,
          card: cardTip.card._id,
          cardSlug: cardTip.card.slug,
          content: cardTip.content,
          buttonText: cardTip.buttonText,
          linkText: card?.linkText,
          issuer: issuer,
          sortPosition: card?.sortPosition,
          sort: card?.context?.sort,
          tipContentBlockDisplay: cardTip.tipContentBlockDisplay,
          tipContentBlockSelected: cardTip.tipContentBlockSelected,
        }
      })
    }

    React.useEffect(() => {
      const hasCards = cards && cards.length
      const cardTips = hasCards
        ? attachSortPositionToCardTips(cards, props.value.cardTips)
        : []

      setCardTipsToSort(cardTips)
      setArrangementId(uuid())
    }, [cards?.length, cards?.length && cards[0].context?.sort, sort])

    const pinnedCardTips = props.value.pinnedCardTips
      ? props.value.pinnedCardTips.map((pinnedCard) => {
          return {
            card: {
              _id: pinnedCard.card._id,
              slug: pinnedCard.card.slug as string,
            },
            frequencyPercent: pinnedCard.frequencyPercent,
          }
        })
      : []

    const pinnedProduct = formatPinnedProducts(pinnedCardTips)

    const { experiment: configExp352, isLoading: isLoadingExp352 } =
      useExperiment('exp_352_bing_native_1st_card_pos_swap')

    React.useEffect(() => {
      if (cardTipsToSort && cardTipsToSort.length) {
        const sortedCardTips = cardTipsToSort
          .filter((item: CardTip) => item.sortPosition !== undefined)
          .sort((a: any, b: any) => {
            return a.sortPosition - b.sortPosition
          })

        if (sort === 'random-sort') {
          setCardTips(sortedCardTips)
          return
        }

        const isInExp352 = statsig.checkGate(
          'exp_352_bing_native_1st_card_pos_swap_gate',
        )

        if (!isLoadingExp352 && isInExp352) {
          const { groupName } = configExp352

          const pinnedProductsExp352 = {
            ...pinnedProduct,
            'discover-it-cash-back': 100,
            reflect: 100,
          }

          let pinnedPositions = {}

          // find the appropriate slug and unshift it to the front
          if (groupName === 'v1') {
            const slug = cardSlugs.find(
              (slug) => slug === 'discover-it-cash-back',
            )
            if (slug) {
              cardSlugs.unshift(cardSlugs.splice(cardSlugs.indexOf(slug), 1)[0])
            }
            pinnedPositions = mapEntityPositions(cardSlugs)
          } else if (groupName === 'Control') {
            const slug = cardSlugs.find((slug) => slug === 'reflect')
            if (slug) {
              cardSlugs.unshift(cardSlugs.splice(cardSlugs.indexOf(slug), 1)[0])
            }
            pinnedPositions = mapEntityPositions(cardSlugs)
          }

          const cardTips = pinEntities(
            sortedCardTips,
            pinnedProductsExp352,
            pinnedPositions,
          )

          setCardTips(cardTips)
        } else {
          const originalPositions = mapEntityPositions(cardSlugs)

          const cardTips = pinEntities(
            sortedCardTips,
            pinnedProduct,
            originalPositions,
          )

          setCardTips(cardTips)
        }
      }
    }, [
      cardTipsToSort?.length,
      JSON.stringify(cardTipsToSort),
      isLoadingExp352,
      sort,
      JSON.stringify(configExp352),
      JSON.stringify(pinnedProduct),
    ])

    const referencedCardKeys = Object.keys(referencedCards || {})
    const hasReferencedCards =
      referencedCardKeys && referencedCardKeys.length > 0

    React.useEffect(() => {
      if ((cardTips && cardTips.length === 0) || !hasReferencedCards) return
      const cardsInOrder = cardTips
        .map((tip) => {
          return prop(tip.cardSlug, referencedCards)
        })
        .filter((card) => card != null) as CompareCredit.Entities[]
      if (arrangementId === 'primary' || arrangementId === null) {
        // Send to Sentry
        Sentry.captureMessage('arrangementId is primary or null', {
          contexts: {
            context: {
              arrangementId,
              file: '/components/sanity-serializers/tipCardTileList/index.tsx',
              path: window.location.pathname,
            },
          },
        })
      }
      viewProductList({
        entities: cardsInOrder,
        category: `tip:${props.value.tag.id}`,
        arrangementId: arrangementId,
      })
    }, [cardTips?.length, hasReferencedCards])

    const userIsOnBrave = useIsUserOnBrave()

    const shownCardTips = userIsOnBrave
      ? cardTips.filter((cardTip) => cardTip?.issuer !== 'bank-of-america')
      : cardTips

    const shouldRenderCardTiles =
      !!shownCardTips && shownCardTips.length > 0 && !!referencedCards

    // For now we want this to be a mobile trigger only for multi-card lists
    let modalTriggerPosition: number | undefined
    if (isMobile) {
      modalTriggerPosition =
        cards && cards.length < (data.exitModalMultiCardTipPosition || 0)
          ? cards.length
          : data.exitModalMultiCardTipPosition
    }

    return (
      <ErrorBoundary>
        <div
          className={`c-card-tile-list__container / mb-4 ${
            listClassName ? listClassName : ''
          }`}
        >
          {shouldRenderCardTiles ? (
            shownCardTips.map((tip: any, idx: number) => {
              return referencedCards[tip.cardSlug] ? (
                <TipCardTileDefault
                  key={tip._key}
                  referencedCards={referencedCards}
                  cardOrderIds={cardOrderIds}
                  tip={tip}
                  tag={props.value.tag}
                  categoryTag={props.value.categoryTag}
                  idx={idx}
                  modalTriggerPosition={modalTriggerPosition}
                  recommendedCredit={props.value.recommendedCredit}
                  exitModalRef={data.exitModalRef}
                  productDisplay={props.value.productDisplay}
                  arrangementId={arrangementId}
                />
              ) : null
            })
          ) : (
            <LoadingSkeleton n={4} />
          )}
        </div>
      </ErrorBoundary>
    )
  }
  return TipCardTileListRender
}
