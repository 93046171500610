import * as React from 'react'
import Link from 'next/link'
import Image from 'next/image'
import { nextImageHelper } from '../../../utils'
import { awardsHptoPageLinkClicked } from '../../../clients/segment'

export function HeroHpto() {
  const handleClick = () => {
    awardsHptoPageLinkClicked({
      action: 'clicked',
    })
  }

  return (
    <div className="c-awards-hpto / relative z-10 / w-full-vw / -mt-16 lg:-mt-20 / pt-24 sm:pt-32 lg:pt-36 xl:pt-40 / pb-4 lg:pb-20 xl:pb-16">
      <div className="container-fluid">
        <header className="relative z-2 / max-w-md sm:max-w-3xl lg:max-w-none mx-auto">
          <div className="relative z-2 / sm:flex sm:flex-row / mb-4 sm:mb-0">
            <div className="c-awards-hpto__badge / shrink-0 / w-36 md:w-40 lg:w-56 / hidden sm:block">
              <Image
                src="/static/awards/cc-awards-ribbon-2025-clr.svg"
                className="inline-block w-full"
                alt="Best Credit Cards of 2025"
                width={255}
                height={274}
                loading="lazy"
                style={nextImageHelper.intrinsic}
              />
            </div>
            <div className="md:w-full / sm:pl-2 lg:pl-6">
              <h1 className="mb-2 / text-white tracking-snug / leading-tight">
                <span className="inline-block w-full / tracking-wide font-semibold uppercase xs:text-lg lg:text-xl xl:text-[21px]">
                  Kick off the New Year with Our
                </span>{' '}
                <span className="flex flex-col / font-serif-dm leading-[1] text-[42px] xs:text-[52px] sm:text-[54px] md:text-[66px] lg:text-[88px] xl:text-[106px]">
                  <span className="block">Best Credit Cards</span>{' '}
                  <span className="block / text-right">
                    of{' '}
                    <span className="text-shadow-none / text-primary">
                      2025
                    </span>
                  </span>
                </span>
              </h1>
            </div>
          </div>
          <div className="relative z-2 / flex flex-row items-center sm:flex-col sm:items-start / mb-6 sm:pl-36 md:pl-40 lg:pl-56">
            <div className="c-awards-hpto__badge / shrink-0 / w-24 / sm:hidden">
              <Image
                src="/static/awards/cc-awards-ribbon-2025-clr.svg"
                className="inline-block w-full"
                alt="Best Credit Cards of 2025"
                width={255}
                height={274}
                loading="lazy"
                style={nextImageHelper.intrinsic}
              />
            </div>
            <div className="md:w-[20rem] xl:w-[24rem] / md:-mt-[3.5rem] lg:-mt-[6.5rem] xl:-mt-[6rem] sm:mb-4 / pl-2 lg:pl-6 / ">
              <p className="sm:mb-4 md:mb-6 xl:mb-8 / text-white text-left xl:text-lg leading-[1.5] text-shadow-base">
                Our 2025 credit card awards are here. Explore the winners and
                choose a card that sets you up for success this&nbsp;year.
              </p>
              <p className="relative z-2 / sm:w-full / sm:text-left / hidden sm:block">
                <Link
                  href={'/awards/'}
                  onClick={handleClick}
                  className="c-btn c-btn--primary / sm:ml-0 / text-xl shadow-xl"
                >
                  <span className="text-shadow-none">See the Winners</span>
                </Link>
              </p>
            </div>
          </div>
          <p className="relative z-2 / sm:w-full / sm:hidden">
            <Link
              href={'/awards/'}
              onClick={handleClick}
              className="c-btn c-btn--primary / sm:ml-0 / text-xl shadow-xl"
            >
              <span className="text-shadow-none">See the Winners</span>
            </Link>
          </p>
          <div
            className="c-awards-hpto__cards / absolute z-1 / top-[5.5rem] / mx-auto"
            role="presentation"
          >
            <div className="c-awards-hpto__card c-awards-hpto__card-1 / absolute z-3 shadow-lg">
              <Image
                src="/static/cardArt/generic-card-burst-orange.png"
                className="inline-block w-full"
                alt="card"
                width={255}
                height={274}
                loading="lazy"
                style={nextImageHelper.intrinsic}
                aria-hidden={true}
              />
            </div>
            <div className="c-awards-hpto__card c-awards-hpto__card-2 / absolute z-2 / left-[2rem] top-[1rem] xl:top-[1.5rem] / shadow-lg">
              <Image
                src="/static/cardArt/generic-card-metal-black.png"
                className="inline-block w-full"
                alt="card"
                width={255}
                height={274}
                loading="lazy"
                style={nextImageHelper.intrinsic}
                aria-hidden={true}
              />
            </div>
            <div className="c-awards-hpto__card c-awards-hpto__card-3 / absolute z-1 / left-[4rem] xl:left-[3rem] top-[3rem] xl:top-[4.25rem] / shadow-lg">
              <Image
                src="/static/cardArt/generic-card-gray-fades.png"
                className="inline-block w-full"
                alt="card"
                width={255}
                height={274}
                loading="lazy"
                style={nextImageHelper.intrinsic}
                aria-hidden={true}
              />
            </div>
          </div>
        </header>
      </div>
      <style jsx>
        {`
          .c-awards-hpto {
            background-image: url(/static/images/bg-hpto-awards-m.jpg);
            background-size: cover;
            background-position: bottom center;
            background-repeat: no-repeat;
            text-shadow: 0 1px 0 rgba(0, 89, 163, 1);
          }
          .text-shadow-none {
            text-shadow: none;
          }
          .c-awards-hpto__badge {
            top: 2rem;
            position: relative;
            opacity: 0;
            animation-name: fadeInOpacity, animateTopNorthRelative;
            animation-duration: 2s;
            animation-fill-mode: forwards;
            animation-delay: 0.3s;
          }
          @media (min-width: 640px) {
            .c-awards-hpto {
              text-shadow: -1px 2px 0 rgba(0, 89, 163, 1);
            }
          }
          @media (min-width: 1024px) {
            .c-awards-hpto {
              background-image: url(/static/images/bg-hpto-awards-lg.jpg);
            }
          }
          @media (min-width: 1700px) {
            .c-awards-hpto {
              background-size: 100% 100%;
            }
          }

          /* ========== CARDS ==========*/
          .c-awards-hpto__cards {
            height: 2rem;
            top: 0.75rem;
            left: 0;
            right: 0;
            margin-left: 35%;
            margin-right: auto;
            transform: scale(0.7);
          }
          @media (min-width: 480px) {
            .c-awards-hpto__cards {
              top: 1rem;
              margin-left: 45%;
              transform: scale(0.9);
            }
          }
          @media (min-width: 640px) {
            .c-awards-hpto__cards {
              top: 1.5rem;
              margin-left: 60%;
              transform: scale(0.9);
            }
          }
          @media (min-width: 768px) {
            .c-awards-hpto__cards {
              top: 1rem;
              margin-left: 60%;
              transform: scale(1);
            }
          }
          @media (min-width: 1024px) {
            .c-awards-hpto__cards {
              top: 2rem;
              margin-left: 78%;
              transform: scale(1.6);
            }
          }
          @media (min-width: 1152px) {
            .c-awards-hpto__cards {
              top: 2.5rem;
              transform: scale(2);
            }
          }

          /* ===== CARD ===== */
          .c-awards-hpto__card {
            width: 8rem;
            transform-origin: bottom left;
            opacity: 0;
          }
          .c-awards-hpto__card-1 {
            animation-name: fadeInOpacity, animateCard1;
            animation-duration: 1s;
            animation-fill-mode: forwards;
            animation-timing-function: ease-in;
            animation-delay: 0.1s;
          }
          .c-awards-hpto__card-2 {
            animation-name: fadeInOpacity, animateCard2;
            animation-duration: 1s;
            animation-fill-mode: forwards;
            animation-timing-function: ease-in;
            animation-delay: 0.2s;
          }
          .c-awards-hpto__card-3 {
            animation-name: fadeInOpacity, animateCard3;
            animation-duration: 1s;
            animation-fill-mode: forwards;
            animation-timing-function: ease-in;
            animation-delay: 0.3s;
          }

          /* ========== ANIMATION ==========*/
          @keyframes animateCard1 {
            from {
              transform: rotate(-0) skewX(0);
            }
            to {
              transform: rotate(-95deg) skewX(5deg);
            }
          }
          @keyframes animateCard2 {
            from {
              transform: rotate(-0) skewX(0);
            }
            to {
              transform: rotate(-60deg) skewX(8deg);
            }
          }
          @keyframes animateCard3 {
            from {
              transform: rotate(-0) skewX(0);
            }
            to {
              transform: rotate(-30deg) skewX(11deg);
            }
          }
        `}
      </style>
    </div>
  )
}
