import * as React from 'react'
import {
  BestCards,
  ArticlesFeatured,
  HeroCategoryTiles,
  LayoutMain,
  ProductsLayout,
} from '../components'

import { HeroHpto } from '../components/hero-hpto/awards/awards-2025'

import { NextSeo } from 'next-seo'
import { CompareCredit } from '../../types'
import { buildStaticProps } from '../ssg/home'
import { useFetchCardsSlug } from '../utils'

interface Article {
  headline: string
  _type: 'tipV2'
  slug: { current: string }
  route: { slug: string }
  image: { url: string; metadata: { lqip: string } }
  contentPreview: any
}

function Index({
  featuredSlugs,
  homepageTakeover,
  homePageData,
}: {
  categories: any
  featuredSlugs: string[]
  homepageTakeover: {
    active: boolean
    cards: CompareCredit.FormattedCard[]
    headline: string
  }
  homePageData: {
    trendingPosts: Article[]
    featuredGuide: Article[]
    featuredImageBanner?: string
    seoTitle: string
    seoDescription: string
  }
  bestPageCards: CompareCredit.BestPageCards
}) {
  const { cards: cardsToRender } = useFetchCardsSlug(featuredSlugs)

  return (
    <>
      <NextSeo
        title={
          homePageData.seoTitle ||
          `Compare Credit | Compare The Best Credit Offers`
        }
        description={homePageData.seoDescription}
      />
      <LayoutMain mixTheme={false} onHome lightTheme={true}>
        {homepageTakeover.active ? (
          <>
            <HeroHpto />
            <ProductsLayout />
          </>
        ) : (
          <>
            <HeroCategoryTiles />
          </>
        )}

        <BestCards cards={cardsToRender} style={'t-light'} />

        <div className="pb-12 lg:pb-16 pt-16">
          <ArticlesFeatured
            featuredGuide={homePageData.featuredGuide[0]}
            trendingPosts={homePageData.trendingPosts}
            featuredImageBanner={homePageData.featuredImageBanner}
          />
        </div>
      </LayoutMain>
    </>
  )
}

export async function getStaticProps() {
  return await buildStaticProps()
}

export default Index
