import React, { useCallback } from 'react'
import Cookies from 'js-cookie'
import path from 'lodash/fp/path'
import prop from 'lodash/fp/prop'
import { useInView } from 'react-intersection-observer'
import { v4 as uuid } from 'uuid'

import { UserContext } from '../'
import { CompareCredit } from '../../../types/compare-credit'
import { fireHoldUpModal, makeImpression } from '../../clients/segment'
import { getExperiment, isStorageAccessible } from '../../utils'
import useNetworkState from '../../utils/use-network-status'
import { buildSecureHref } from '../../utils/buildSecureHref'
import { ModalLeaveBehindContext } from '../modal-leave-behind-context'

export const ApplyNowLinkV2 = (data: {
  externalId: string
  cardOrderIds: Record<string, string>
  categoryId: string
  referencedCards: null | Record<string, CompareCredit.Entities>
}) => {
  const ApplyNowLinkV2Render = (props: {
    children: React.ReactElement
    value: { slug: string }
  }) => {
    const { children, value } = props
    const { externalId, cardOrderIds, referencedCards, categoryId } = data
    const entitySlug = value.slug

    const networkInfo = useNetworkState()

    const orderId = React.useMemo<string>(
      () => path([entitySlug], cardOrderIds) || uuid(),
      [entitySlug, JSON.stringify(cardOrderIds)],
    )
    const entity = prop(
      entitySlug,
      referencedCards,
    ) as CompareCredit.FormattedCard

    const { clientLocation, entryQuery, gaSessionInfo } =
      React.useContext(UserContext)
    const {
      handleSetOpenModalLeaveBehind,
      modalLeaveBehindId,
      modalLeaveBehindTipSlug,
    } = React.useContext(ModalLeaveBehindContext)

    // Secure page exposure experiment
    // const { initialized } = React.useContext(StatsigContext)
    // const [secureExp, setSecureExp] = React.useState<any>(null)
    // React.useEffect(() => {
    //   if (!initialized) return
    //   const exp = Statsig.getExperiment('secure_direct')
    //   const group = exp.get('group', 'control')
    //   setSecureExp(group)
    // }, [initialized])

    const { ref, inView } = useInView({
      threshold: 0.9,
      triggerOnce: true,
    })

    const sessionStorageExist = isStorageAccessible()
    const leaveBehind = sessionStorageExist
      ? sessionStorage.getItem(`modalLeaveBehind-${modalLeaveBehindTipSlug}`)
      : null

    React.useEffect(() => {
      const experiment = getExperiment()
      const ga_client_id = Cookies.get('_ga') || null

      if (inView && orderId && entity)
        makeImpression({
          ga_client_id,
          ga_session_id: gaSessionInfo.ga_session_id,
          ga_session_number: gaSessionInfo.ga_session_number,
          arrangementId: null,
          entryQuery,
          experiment,
          latency: null,
          location: clientLocation,
          params: null,
          userModel: null,
          category: 'credit-card',
          order_id: orderId ? orderId : uuid(),
          brand: entity.issuer.slug.current,
          id: entity.slug,
          name: entity.name,
          network: networkInfo,
          product_id: entity.slug,
          sku: entity.slug,
          categoryId: categoryId,
          _rev: entity._rev,
        })
    }, [inView])

    const href = buildSecureHref({
      orderId: orderId,
      slug: entitySlug,
      externalId,
      pathName: typeof window !== 'undefined' ? window?.location?.pathname : '',
      linkParams: entity?.applyNowLinkParameters,
      expGroup: null,
    })

    const handleModalLeaveBenind = useCallback(
      (event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault()

        // 1. Leave behind
        const win = window.open(href, '_blank')

        // 2. Fire leave behind
        fireHoldUpModal({
          modal_id: modalLeaveBehindId || '',
          type: 'apply_now_clicked',
          meta: {
            redirect_order_id: orderId,
          },
        })

        // 3. Trigger events
        const intervalId = setInterval(() => {
          if (win?.opener == null) {
            if (leaveBehind !== 'true') {
              handleSetOpenModalLeaveBehind()
            }

            fireHoldUpModal({
              modal_id: modalLeaveBehindId || '',
              type: 'rendered',
              meta: {
                redirect_order_id: orderId,
              },
            })

            clearInterval(intervalId)
          }
        }, 500)
      },
      [
        href,
        entitySlug,
        handleSetOpenModalLeaveBehind,
        orderId,
        modalLeaveBehindId,
        leaveBehind,
        sessionStorageExist,
      ],
    )

    return (
      <>
        {entitySlug && entity ? (
          <a
            className="mt-8 mb-2 / text-primary-bright / hover:text-primary-mid"
            href={href}
            target="_blank"
            rel="noreferrer"
            ref={ref}
            onClick={handleModalLeaveBenind}
          >
            {children}
          </a>
        ) : (
          <span>{children}</span>
        )}
      </>
    )
  }
  return ApplyNowLinkV2Render
}
