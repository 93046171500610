import React from 'react'
import Link from 'next/link'
import { PortableText, PortableTextBlockComponent } from '@portabletext/react'
import { v4 as uuid } from 'uuid'

import { StarRatingEditor } from '..'
import { supify } from '../../utils'
import { CompareCredit } from '../../../types/compare-credit'
import { CardArt } from '../card-art'
import { clickRatesAndFees } from '../../clients/segment'

import cn from 'utils/classnames'

const BlockRenderer = ({ children }: { children: any }) => (
  <span>{children}</span>
)
const AttributeRenderer = (props: { card: any }) => {
  function AttributeRendererRender<
    K extends keyof CompareCredit.Entity,
  >(attrProps: { value: { attribute: K } }) {
    return React.createElement(
      'span',
      {},
      props.card[attrProps.value.attribute] as React.ReactNode,
    )
  }
  return AttributeRendererRender
}

export function CardPreview(props: {
  card: CompareCredit.FormattedCard
  last?: boolean
  listItems: any[]
}) {
  const { card } = props
  const orderId = React.useMemo<string>(() => uuid(), [])

  return (
    <div
      className={cn(
        'relative / flex flex-col items-stretch / w-full / mx-auto max-w-sm md:-mb-12 / px-6 pb-5 lg:px-8 / bg-white rounded-lg',
        {
          'mb-0 md:mb-0': props.last,
          'mb-8 md:mb-0': !props.last,
          'mt-10 md:mt-8': card.customBadge,
          'mt-8': !card.customBadge,
        },
      )}
      style={{
        boxShadow: '0 2px 6px 0 rgba(120,130,157,0.24)',
      }}
    >
      {card.customBadge && (
        <div className="absolute left-0 right-0 / w-full / -mt-14 / text-sm font-bold text-primary uppercase">
          <span className="c-ribbon c-ribbon--slimmer relative inline-block / w-auto / mx-auto px-4 md:px-2 lg:px-4 / bg-tetriary">
            <span className="inline-block pt-0.25 leading-snug overflow-hidden">
              {card.customBadge}
            </span>
          </span>
        </div>
      )}
      <div className="c-card-art relative w-4/5 md:w-full max-w-13rem / mx-auto -mt-8 mb-3 / rounded-lg shadow text-center">
        <div className="c-card-art__container / relative / max-w-xs / rounded xs:rounded shadow overflow-hidden">
          <Link
            href={`/credit-cards/${card.issuer.slug.current}/${card.slug}`}
            className="relative flex overflow-hidden rounded-lg shadow z-0"
          >
            <span className="c-card-art__content / absolute / flex flex-wrap content-center items-center / z-10 left-0 / w-full h-full text-white text-base lg:text-lg">
              <span className="w-full font-semibold">Learn More</span>
            </span>

            <CardArt
              _rev={card._rev}
              cardArt={card.cardArt}
              imgClasses="relative z-0 w-full h-full object-cover"
              customCodeSnippets={card.customCodeSnippets}
              issuer={card.issuer.slug.current}
              name={card.name}
              slug={card.slug}
              externalId={null}
              categoryId={null}
              orderId={orderId}
            />
          </Link>
        </div>
      </div>

      <div className="grow / flex flex-col items-stretch w-full justify-between / text-center">
        <div>
          <Link href={`/credit-cards/${card.issuer.slug.current}/${card.slug}`}>
            <div
              className="c-card-title / mb-2 / font-sans font-bold text-lg xs:text-xl md:text-base lg:text-lg xl:text-xl text-primary-mid leading-tight / hover:text-primary-bright / transition-all / js-card-title / cursor-pointer"
              dangerouslySetInnerHTML={{ __html: supify(card.name) }}
            />
          </Link>
          <div className="mb-2">
            <StarRatingEditor
              productSlug={card.slug}
              stars={card.expertReviewRating}
            />
          </div>
          <div className="w-full / mb-4 py-1 / text-sm text-left / border-t-2 border-b-2 border-gray-400">
            <ul className="c-highlight__list / text-sm">
              {props.listItems.map((item, i) => {
                return (
                  <li
                    key={i}
                    className={cn(
                      'c-highlight__item flex flex-wrap / w-full items-start /  py-2 md:py-3',
                      {
                        'border-b': ++i !== props.listItems.length,
                      },
                    )}
                  >
                    <label className="c-highlight__label / w-full / mb-1 / xs:w-1/3 pr-2 font-bold text-card-dark">
                      {item.Title}
                    </label>
                    <span className="c-highlight__description / w-full / xs:w-2/3 /  text-card-gray flex items-center">
                      <PortableText
                        value={item.Description}
                        components={{
                          block: {
                            normal: BlockRenderer as PortableTextBlockComponent,
                          },
                          types: {
                            cardAttribute: AttributeRenderer(props),
                          },
                        }}
                      />
                    </span>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
        <div className="w-full">
          <Link href={`/credit-cards/${card.issuer.slug.current}/${card.slug}`}>
            <button className="c-btn c-btn--ghost">Learn More</button>
          </Link>
          {card.ratesAndFees && card.ratesAndFees && (
            <div className="w-full / pt-1 / text-center text-shadow">
              {card.ratesAndFees && card.ratesAndFees !== 'N/A' && (
                <p className="mb-0.5">
                  <a
                    className="inline-block text-primary-bright text-fs12 leading-snug / hover:text-primary-mid"
                    href={card.ratesAndFees}
                    onClick={() => {
                      clickRatesAndFees({
                        applyNowLink: card.applyNowLink,
                        component: 'Card Preview',
                        name: card.name,
                        url: window.location.href,
                      })
                    }}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Rates &amp; Fees
                  </a>
                </p>
              )}
              {card.issuer.slug.current === 'american-express' && (
                <p className="text-fs12 text-slate-500 / text-center / w-full leading-snug">
                  Terms Apply
                </p>
              )}
            </div>
          )}
        </div>
      </div>
      <style jsx>{`
        @media (min-width: 375px) {
          .c-highlight__label {
            width: 30%;
          }
          .c-highlight__description {
            width: 66.666666%;
            padding-left: 1rem;
          }
        }
        @media (min-width: 640px) {
          .c-highlight__label {
            width: 40%;
          }
          .c-highlight__description {
            width: 60%;
          }
        }
        @media (min-width: 768px) {
          .c-highlight__label {
            width: 100%;
          }
          .c-highlight__description {
            width: 100%;
            padding-left: 0;
          }
        }
      `}</style>
    </div>
  )
}
