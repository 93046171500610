import * as React from 'react'
import Image from 'next/image'

import {
  AddToCompareBtn,
  CallToApply,
  LinkToRedirect,
} from '../../../../components'
import { CompareCredit } from '../../../../../types/compare-credit'
import { firstIsVowel, nextImageHelper } from '../../../../utils'
import { SecureSite } from '../../../secure-site'
import { clickRatesAndFees } from '../../../../clients/segment'
import Link from 'next/link'

export function CTAs({
  slug,
  phoneNumber,
  isMobile,
  entity,
  inCart,
  updateCart,
  comparisonDisabled,
  orderId,
  externalId,
}: {
  slug: string
  phoneNumber?: CompareCredit.FormattedCard['phoneNumber']
  isMobile: boolean
  entity: CompareCredit.FormattedCard | CompareCredit.NonPaidCard
  inCart: boolean
  updateCart: (arg0: 'add' | 'remove') => void
  comparisonDisabled?: boolean
  orderId: string
  externalId: string
}) {
  const issuerName = entity.issuer.name
  const issuerSecureSite = (entity as CompareCredit.FormattedCard)
    .issuerSecureSite
  const isCard = entity._type === 'card'
  return (
    <div className="flex flex-col md:flex-row items-start justify-start / w-full lg:max-w-lg / mb-4 sm:mb-6 md:mb-0 lg:mt-4">
      <div className="flex flex-col justify-start mb-3 md:mb-0 md:mr-4 lg:mr-6 w-full md:w-1/2">
        <div className="flex flex-col items-center">
          <div className="w-full">
            {isCard ? (
              <LinkToRedirect
                externalId={externalId}
                linkParams={entity.applyNowLinkParameters}
                orderId={orderId}
                slug={slug}
              >
                <div
                  className={`c-btn c-btn--primary mb-3 md:ml-0 py-2 text-lg md:text-base lg:text-lg w-full / trk_card-detail_cta trk_card-detail_cta_${slug}`}
                >
                  Apply Now
                  <span className="c-btn__icon / inline-block w-5 ml-2">
                    <Image
                      height={20}
                      width={20}
                      alt="lock icon"
                      src="/static/icons/icon-lock-round-w.svg"
                      style={nextImageHelper.responsive}
                      sizes="100vw"
                    />
                  </span>
                </div>
              </LinkToRedirect>
            ) : (
              <Link href={entity.moreInfoLink}>
                <div
                  className={`c-btn c-btn--secondary mb-3 md:ml-0 py-2 text-lg md:text-base lg:text-lg w-full / trk_card-detail_cta trk_card-detail_cta_${slug}`}
                >
                  Learn More
                </div>
              </Link>
            )}
          </div>

          <p className="text-xs text-gray-600 / text-center / -mt-1 lg:px-0 / md:self-start w-full max-w-xs leading-4">
            {isCard && (
              <SecureSite
                issuerName={issuerName}
                issuerSecureSite={issuerSecureSite}
              />
            )}
          </p>

          {phoneNumber && isMobile && isCard && (
            <div className="w-full mt-3">
              <CallToApply
                classes="text-lg md:text-base lg:text-lg / mb-4"
                entity={entity}
              />
              <p className="w-full text-xs text-gray-600 / text-center / -mt-2 -mb-2 md:mb-2 mb-2 / md:self-start leading-4">
                with {firstIsVowel(issuerName) ? 'an' : 'a'} {issuerName}{' '}
                representative
              </p>
            </div>
          )}
        </div>
      </div>
      {!comparisonDisabled && isCard ? (
        <div className="w-full md:w-1/2">
          <AddToCompareBtn detailsTop inCart={inCart} updateCart={updateCart} />
        </div>
      ) : null}
    </div>
  )
}

export function ListCTAs({
  externalId,
  slug,
  phoneNumber,
  isMobile,
  clientLocation,
  entity,
  inCart,
  updateCart,
  orderId,
  position,
}: {
  externalId: string
  slug: string
  phoneNumber: CompareCredit.FormattedCard['phoneNumber']
  isMobile: boolean
  clientLocation: { country: string | undefined; state: string | undefined }
  entity: CompareCredit.FormattedCard
  inCart: boolean
  updateCart: (arg0: 'add' | 'remove') => void
  orderId: string
  view?: string
  position?: number
}) {
  const issuerName = entity.issuer.name
  const issuerSecureSite = entity.issuerSecureSite

  return (
    <div
      className={`mb-7 md:mb-0 / md:w-4/12 mx-auto lg:mb-0 lg:w-4/12 lg:flex lg:justify-end `}
    >
      <div className={`w-full / xl:w-56`}>
        <LinkToRedirect
          externalId={externalId}
          linkParams={entity.applyNowLinkParameters}
          orderId={orderId}
          position={position}
          slug={slug}
        >
          <div
            className={`c-btn c-btn--primary / text-lg md:text-base lg:text-lg / ${
              phoneNumber && isMobile && clientLocation.country === 'US'
                ? 'mb-2'
                : 'mb-3'
            }
            `}
          >
            Apply Now
            <span className="c-btn__icon / inline-block w-5 ml-2">
              <Image
                height={20}
                width={20}
                alt="lock icon"
                src="/static/icons/icon-lock-round-w.svg"
                style={nextImageHelper.responsive}
                sizes="100vw"
              />
            </span>
          </div>
        </LinkToRedirect>

        <p className="text-xs text-gray-600 / text-center / -mt-1 mb-3 md:mb-1 mx-auto / w-full max-w-xs leading-4">
          <SecureSite
            issuerName={issuerName}
            issuerSecureSite={issuerSecureSite}
          />
        </p>

        {phoneNumber && isMobile && (
          <div className="w-full mt-3">
            <CallToApply
              classes="text-lg md:text-base lg:text-lg / mb-4"
              entity={entity}
            />
            <p className="text-xs text-gray-600 / text-center / -mt-2 mb-4 / md:self-start w-full max-w-xs leading-4">
              with {firstIsVowel(issuerName) ? 'an' : 'a'} {issuerName}{' '}
              representative
            </p>
          </div>
        )}

        <div className="md:hidden">
          <p className="mb-2 / sm:text-lg">
            <AddToCompareBtn inCart={inCart} updateCart={updateCart} />
          </p>

          {entity.ratesAndFees && entity.ratesAndFees !== 'N/A' && (
            <p className="text-primary-bright text-xs w-full text-center pt-1">
              <a
                href={entity.ratesAndFees}
                onClick={() => {
                  clickRatesAndFees({
                    applyNowLink: entity.applyNowLink,
                    component: 'Card List Mobile CTA',
                    name: entity.name,
                    url: window.location.href,
                  })
                }}
                rel="noopener noreferrer"
                target="_blank"
              >
                Rates &amp; Fees
              </a>
            </p>
          )}
          {entity.issuer.slug.current === 'american-express' && (
            <p className="text-fs10 text-gray-600 / text-center / mb-3 md:mb-1 / w-full leading-4">
              Terms Apply
            </p>
          )}
        </div>
      </div>
    </div>
  )
}
