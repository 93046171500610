import React from 'react'
import isEmpty from 'lodash/fp/isEmpty'
import propOr from 'lodash/fp/propOr'
import { CompareCredit } from '../../../../types/compare-credit'
import { useExperiment } from 'utils/use-experiment-wrapper'

import { autoScroll, supify } from 'utils/'
import { SkeletonPreview } from './skeleton'
import { formatPinnedProducts } from 'ssg/utils/format-pinned-products'
import mapEntityPositions from 'utils/mapEntityPositions'
import { pinEntities } from 'utils/pin-entities'

export const PreviewCards = (props: {
  value: {
    cardTips: CompareCredit.Tip[]
    tag: CompareCredit.CategoryTag
    title?: string
    pinnedCardTips?: CompareCredit.PinnedProduct[]
    sort: string
    referencedCards: null | Record<string, CompareCredit.Entities>
  }
}) => {
  const { title, sort, referencedCards } = props.value

  // 1. CARDS
  const cardSlugs = props.value.cardTips.map(
    (cardPreview) => cardPreview.card.slug,
  )
  const cardsLength = props.value.cardTips.length
  const [cards, setCards] = React.useState<any[]>([])
  const [cardsToSort, setCardsToSort] = React.useState<any[]>([])

  // 2. Scroll to section
  const handleClickCardSection = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    const dataId = event.currentTarget.getAttribute('data-id')
    if (dataId) {
      const productSection = document.getElementById(`card-${dataId}`)
      if (productSection) {
        autoScroll(productSection, -88)
      }
    }
  }

  React.useEffect(() => {
    setCardsToSort(
      isEmpty(referencedCards)
        ? []
        : cardSlugs
            .map((slug) => propOr(null, slug, referencedCards))
            .filter((x) => x),
    )
  }, [sort, JSON.stringify(referencedCards), JSON.stringify(cardSlugs)])

  const pinnedCardTips = props.value.pinnedCardTips
    ? props.value.pinnedCardTips.map((pinnedCard) => {
        return {
          card: {
            _id: pinnedCard.card._id,
            slug: pinnedCard.card.slug as string,
          },
          frequencyPercent: pinnedCard.frequencyPercent,
        }
      })
    : []

  const pinnedProduct = formatPinnedProducts(pinnedCardTips)

  const { experiment, isLoading } = useExperiment('pinning_sort_position')

  React.useEffect(() => {
    if (cardsToSort && cardsToSort.length) {
      const sortedCardTips = cardsToSort
        .filter((item) => item.sortPosition !== undefined)
        .sort((a: any, b: any) => {
          return a.sortPosition - b.sortPosition
        })
      if (sort === 'random-sort') {
        setCards(
          sortedCardTips.map((cardTip: any) => {
            const cardPreview = props.value.cardTips.find(
              ({ card }) => card.slug === cardTip.slug,
            )
            return {
              ...cardPreview,
              card: cardTip,
            }
          }),
        )
        return
      }
      const originalPositions = mapEntityPositions(cardSlugs)
      const cardTips =
        !isLoading && experiment.get('pinned', true)
          ? pinEntities(sortedCardTips, pinnedProduct, originalPositions)
          : sortedCardTips

      setCards(
        cardTips.map((cardTip: any) => {
          const cardPreview = props.value.cardTips.find(
            ({ card }) => card.slug === cardTip.slug,
          )
          return {
            ...cardPreview,
            card: cardTip,
          }
        }),
      )
    }
  }, [
    JSON.stringify(cardsToSort),
    isLoading,
    experiment.get('pinned', true),
    sort,
  ])

  return (
    <div className="c-preview-cards / mb-6 mt-4 / leading-snug text-base">
      <p className="c-preview-cards__title / mb-1 / text-primary-mid font-bold leading-tight">
        {title}
      </p>

      {isEmpty(cards) && <SkeletonPreview n={cardsLength} />}
      <div>
        {cards && (
          <ul className={`c-preview-cards__list / text-sm`}>
            {cards.map(({ card, labelCard }, i) => (
              <li
                key={i}
                className="c-preview-cards__item / flex flex-col sm:block / py-2 / border-b border-gray-300"
              >
                <span className="c-preview-cards__label / shrink-0 / font-semibold">
                  {labelCard}
                </span>
                {` `}
                <span className="c-preview-cards__card-name">
                  <button
                    onClick={handleClickCardSection}
                    data-id={card.slug}
                    className="text-left text-primary-bright / hover:text-primary-mid font-semibold"
                    dangerouslySetInnerHTML={{
                      __html: supify(card.name),
                    }}
                  />
                </span>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  )
}
